import React, { useEffect, useState } from 'react'
import Navbar from '../../components/navbar/Navbar'
import ImageHeader from '../../components/image_header/ImageHeader'
import SEO from '../../components/SEO'
import Chat from '../../components/chat/Chat'
import ScrollTop from '../../components/scroll_top/ScrollTop'
import Footer from '../../components/footer/Footer'
import './privacy_policy.scss'
import axios, { BASE_URL } from '../../api/axios'

const PrivacyPolicy = () => {
    const [privacyPolicy, setPrivacyPolicy] = useState('')
    const [header, setHeader] = useState('')
    const getPrivacyPolicy = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=privacy_policy').then((response) => {
            setPrivacyPolicy(response.data.nilai)
        })
    }
    const getHeader = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=header_privacy_policy')
            .then((response) => {
                setHeader(response.data.nilai)
            })
    }

    useEffect(() => {
        getPrivacyPolicy()
        getHeader()
    }, [])
    return (
        <div id="privacy_policy">
            <SEO title="Privacy Policy" description="Hello World" />
            <Navbar />
            <ImageHeader image={BASE_URL + '/../img/' + header} />
            <div className="container mb-5">
                <div className="title_faq mb-4">
                    <img src={require('../../assets/icons/PRIVACY POLICY.png')} alt="" />
                </div>
                <p dangerouslySetInnerHTML={{ __html: privacyPolicy }} />
            </div>
            <Chat />
            <ScrollTop />
            <Footer />
        </div>
    )
}

export default PrivacyPolicy