import './App.scss';
import Home from './pages/home/Home'
import Login from './pages/sign/Login'
import Logout from './pages/sign/Logout'
import ForgotPassword from './pages/forgot_password/ForgotPassword';
import ResetPassword from './pages/reset_password/ResetPassword';
import Gallery from './pages/gallery/Gallery'
import Sejarah from './pages/sejarah/Sejarah'
import VisiMisiMotto from './pages/visi_misi_motto/VisiMisiMotto'
import StrukturOrganisasi from './pages/struktur_organisasi/StrukturOrganisasi';
import Berita from './pages/berita/Berita';
import BeritaInfo from './pages/berita/BeritaInfo';
import Contact from './pages/contact/Contact';
import TermsConditions from './pages/terms_conditions/TermsConditions';
import PrivacyPolicy from './pages/privacy_policy/PrivacyPolicy';
import Admin from './pages/admin/Admin';
import Dashboard from './pages/admin/pages/Dashboard'
import { default as UserAdmin } from './pages/admin/pages/user/User'
import { default as CarouselAdmin } from './pages/admin/pages/carousel/Carousel'
import TambahCarousel from './pages/admin/pages/carousel/TambahCarousel'
import UbahCarousel from './pages/admin/pages/carousel/UbahCarousel'
import { default as MengapaKamiAdmin } from './pages/admin/pages/mengapa_kami/MengapaKami'
import { default as GalleryAdmin } from './pages/admin/pages/gallery/Gallery'
import TambahGallery from './pages/admin/pages/gallery/TambahGallery'
import UbahGallery from './pages/admin/pages/gallery/UbahGallery'
import { default as ServicesAdmin } from './pages/admin/pages/services/Services'
import TambahServices from './pages/admin/pages/services/TambahServices'
import UbahServices from './pages/admin/pages/services/UbahServices'
import { default as PartnerAdmin } from './pages/admin/pages/partner/Partner'
import TambahPartner from './pages/admin/pages/partner/TambahPartner'
import UbahPartner from './pages/admin/pages/partner/UbahPartner'
import { default as FAQAdmin } from './pages/admin/pages/faq/FAQ'
import TambahFAQ from './pages/admin/pages/faq/TambahFAQ'
import UbahFAQ from './pages/admin/pages/faq/UbahFAQ'
import { default as BeritaAdmin } from './pages/admin/pages/berita/Berita'
import TambahBerita from './pages/admin/pages/berita/TambahBerita'
import UbahBerita from './pages/admin/pages/berita/UbahBerita'
import { default as StrukturOrganisasiAdmin } from './pages/admin/pages/struktur_organisasi/StrukturOrganisasi'
import TambahStrukturOrganisasi from './pages/admin/pages/struktur_organisasi/TambahStrukturOrganisasi'
import UbahStrukturOrganisasi from './pages/admin/pages/struktur_organisasi/UbahStrukturOrganisasi'
import { default as ChatBotAdmin } from './pages/admin/pages/chat_bot/ChatBot'
import TambahChatBot from './pages/admin/pages/chat_bot/TambahChatBot'
import UbahChatBot from './pages/admin/pages/chat_bot/UbahChatBot'
import { default as ChatAdmin } from './pages/admin/pages/chat/Chat'
import { default as ContactAdmin } from './pages/admin/pages/contact/Contact'
import { default as SejarahAdmin } from './pages/admin/pages/sejarah/Sejarah'
import { default as VisiMisiMottoAdmin } from './pages/admin/pages/visi_misi_motto/VisiMisiMotto'
import { default as TermsConditionsAdmin } from './pages/admin/pages/terms_conditions/TermsConditions'
import { default as PrivacyPolicyAdmin } from './pages/admin/pages/privacy_policy/PrivacyPolicy'
import 'bootstrap';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async'
import PersistLogin from './components/persist/PersistLogin';
import PersistAplikasi from './components/persist/PersistAplikasi';
import UbahPassword from './pages/admin/pages/ubah_password/UbahPassword';
import PengaturanWebsite from './pages/admin/pages/pengaturan_website/PengaturanWebsite';
import TambahUser from './pages/admin/pages/user/TambahUser';
import UbahUser from './pages/admin/pages/user/UbahUser';
import PersistAll from './components/persist/PersistAll';
import ChatInfo from './pages/admin/pages/chat/ChatInfo';
import Test from './pages/Test';

const App = () => {
	return (
		<BrowserRouter>
			<HelmetProvider>
				<div className="App">
					<Routes>
						<Route element={<PersistAll />}>
							<Route path="/" element={<Home />} />
							<Route path="/test" element={<Test />} />
							<Route path="/login" element={<Login />} />
							<Route path="/logout" element={<Logout />} />
							<Route path="/forgot_password" element={<ForgotPassword />} />
							<Route path="/reset_password/:uuid" element={<ResetPassword />} />
							<Route path="/gallery" element={<Gallery />} />
							<Route path="/sejarah" element={<Sejarah />} />
							<Route path="/visi_misi_motto" element={<VisiMisiMotto />} />
							<Route path="/struktur_organisasi" element={<StrukturOrganisasi />} />
							<Route path="/berita" element={<Berita />} />
							<Route path="/berita/:id" element={<BeritaInfo />} />
							<Route path="/contact" element={<Contact />} />
							<Route path="/terms_conditions" element={<TermsConditions />} />
							<Route path="/privacy_policy" element={<PrivacyPolicy />} />
							<Route path="/admin" element={<Admin />}>
								<Route path="dashboard" element={<Dashboard />} />
								<Route path="user" element={<UserAdmin />} />
								<Route path="tambah_user" element={<TambahUser />} />
								<Route path="ubah_user/:id" element={<UbahUser />} />
								<Route path="carousel" element={<CarouselAdmin />} />
								<Route path="tambah_carousel" element={<TambahCarousel />} />
								<Route path="ubah_carousel/:id" element={<UbahCarousel />} />
								<Route path="mengapa_kami" element={<MengapaKamiAdmin />} />
								<Route path="gallery" element={<GalleryAdmin />} />
								<Route path="tambah_gallery" element={<TambahGallery />} />
								<Route path="ubah_gallery/:id" element={<UbahGallery />} />
								<Route path="services" element={<ServicesAdmin />} />
								<Route path="tambah_services" element={<TambahServices />} />
								<Route path="ubah_services/:id" element={<UbahServices />} />
								<Route path="partner" element={<PartnerAdmin />} />
								<Route path="tambah_partner" element={<TambahPartner />} />
								<Route path="ubah_partner/:id" element={<UbahPartner />} />
								<Route path="faq" element={<FAQAdmin />} />
								<Route path="tambah_faq" element={<TambahFAQ />} />
								<Route path="ubah_faq/:id" element={<UbahFAQ />} />
								<Route path="berita" element={<BeritaAdmin />} />
								<Route path="tambah_berita" element={<TambahBerita />} />
								<Route path="ubah_berita/:id" element={<UbahBerita />} />
								<Route path="struktur_organisasi" element={<StrukturOrganisasiAdmin />} />
								<Route path="tambah_struktur_organisasi" element={<TambahStrukturOrganisasi />} />
								<Route path="ubah_struktur_organisasi/:id" element={<UbahStrukturOrganisasi />} />
								<Route path="chat_bot" element={<ChatBotAdmin />} />
								<Route path="tambah_chat_bot" element={<TambahChatBot />} />
								<Route path="ubah_chat_bot/:id" element={<UbahChatBot />} />
								<Route path="chat" element={<ChatAdmin />} />
								<Route path="info_chat/:id" element={<ChatInfo />} />
								<Route path="contact" element={<ContactAdmin />} />
								<Route path="sejarah" element={<SejarahAdmin />} />
								<Route path="visi_misi_motto" element={<VisiMisiMottoAdmin />} />
								<Route path="terms_conditions" element={<TermsConditionsAdmin />} />
								<Route path="privacy_policy" element={<PrivacyPolicyAdmin />} />
								<Route path="ubah_password" element={<UbahPassword />} />
								<Route path="pengaturan_website" element={<PengaturanWebsite />} />
							</Route>
						</Route>
					</Routes>
				</div>
			</HelmetProvider>
		</BrowserRouter>
	);
}

export default App;
