import React, { useState, useEffect } from 'react'
import { faEdit, faEllipsisV, faPlus, faSync, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useNavigate } from 'react-router-dom'
import axios, { BASE_URL } from '../../../../api/axios'
import Swal from 'sweetalert2'
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'

const User = () => {
    let nomor = 1
    const axiosPrivate = useAxiosPrivate()
    const [header, setHeader] = useState('')
    const nagivate = useNavigate()
    const [listUser, setListUser] = useState(null)
    const [fileHeader, setFileHeader] = useState(null)
    const [fileHeaderError, setFileHeaderError] = useState('')

    const getUser = async () => {
        const response = await axiosPrivate.get('user')

        if (response?.data && response?.data?.length > 0) {
            setListUser(response.data)
        }
    }

    const getHeader = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=header_login', {
            withCredentials: true
        }).then((response) => {
            if (response?.data?.nilai) {
                setHeader(response.data.nilai)
            }
        })
    }

    const handleFileHeader = (e) => {
        setFileHeader(e.target.files[0])
        setFileHeaderError('')
    }

    const handleHeaderSubmit = (e) => {
        e.preventDefault()
        const form = new FormData()
        form.append('file', fileHeader)
        form.append('nama_konfigurasi', 'header_login')
        axiosPrivate.post('konfigurasi/image_header', form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).catch((err) => {
            if (err.response.data?.errors) {
                if (Array.isArray(err.response.data.errors) === true) {
                    err.response.data.errors.map((val) => {
                        if (val.path === 'file') {
                            setFileHeaderError(val.message)
                        }
                    })
                } else {
                    if (err.response.data.errors.field === 'file') {
                        setFileHeaderError(err.response.data.errors.code)
                    }
                }
            }
        }).then((response) => {
            if (response.status === 200 && response.data?.message === 'Berhasil diubah') {
                Swal.fire({
                    title: 'Berhasil!',
                    text: 'Header login sudah berhasil diubah',
                    icon: 'success',
                }).then(() => {
                    getHeader()
                })
            }
        })
    }

    const hapus = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const response = await axiosPrivate.delete(`user/delete/${id}`)
                if (response.status === 200 && response.data?.message == 'Berhasil dihapus') {
                    Swal.fire(
                        'Berhasil!',
                        'Data user sudah berhasil dihapuskan.',
                        'success'
                    )
                    getUser()
                }
            }
        })
    }

    const resetPassword = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, reset it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const response = await axiosPrivate.post(`user/reset_password/${id}`)
                if (response.status === 200 && response.data?.message == 'Berhasil direset') {
                    Swal.fire(
                        'Berhasil!',
                        'Password user sudah berhasil direset menjadi 12345678.',
                        'success'
                    )
                    getUser()
                }
            }
        })
    }


    useEffect(() => {
        getUser()
        getHeader()
    }, [])
    return (
        <>
            <div className="page-header">
                <h1 className="page-title">Daftar User</h1>
                <div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="https://iuranmasjid.skripsita.online/admin">Admin</a>
                        </li>
                        <li className="breadcrumb-item">
                            <a href={undefined}>Data Master</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            User
                        </li>
                    </ol>
                </div>
            </div>
            <div className="text-wrap mb-4">
                <div className="">
                    <div className="alert alert-info">
                        <div className="d-flex align-items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40" viewBox="0 0 24 24">
                                <path fill="#70a9ee" d="M20.05713,22H3.94287A3.02288,3.02288,0,0,1,1.3252,17.46631L9.38232,3.51123a3.02272,3.02272,0,0,1,5.23536,0L22.6748,17.46631A3.02288,3.02288,0,0,1,20.05713,22Z"></path>
                                <circle cx="12" cy="17" r="1" fill="#1170e4"></circle>
                                <path fill="#1170e4" d="M12,14a1,1,0,0,1-1-1V9a1,1,0,0,1,2,0v4A1,1,0,0,1,12,14Z"></path>
                            </svg>
                            <strong className="ms-2 fw-bolder">Informasi</strong>
                        </div>
                        <hr className="message-inner-separator" />
                        <p> Ini adalah data master. Harap pergunakan data ini dengan baik dan jika sudah tidak diperlukan
                            diperbolehkan untuk dihapus. Namun jika masih diperlukan, maka jangan dihapus karena akan mengakibatkan
                            data lainnya ikut terhapus.</p>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h3 className="card-title">Image Header Authentication</h3>
                </div>
                <div className="card-body">
                    <div className="row align-items-center">
                        <div className="col-md-8">
                            <img src={BASE_URL + '/../img/' + header} alt="Image Header" />
                        </div>
                        <div className="col-md-4">
                            <form method="POST" encType="multipart/form-data" onSubmit={handleHeaderSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="file">File Image Header</label>
                                    <input type="file" className={`form-control ${fileHeaderError !== '' ? 'is-invalid' : ''}`} id="file" name="file" onChange={handleFileHeader} required />
                                    <div className="invalid-feedback">{fileHeaderError}</div>
                                </div>
                                <button type="submit" className="btn btn-primary w-100 rounded-pill">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h3 className="card-title">Daftar User</h3>
                    <div>
                        <Link to="/admin/tambah_user"><FontAwesomeIcon icon={faPlus} /></Link>
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead className="text-center">
                                <tr>
                                    <th className="px-3 align-middle" style={{ 'width': '5px' }}>No</th>
                                    <th className="align-middle">Email Address</th>
                                    <th className="align-middle">Nama Lengkap</th>
                                    <th className="align-middle">Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listUser !== null && listUser.length > 0 ? listUser.map((val, key, arr) =>
                                (
                                    <tr key={val.id}>
                                        <td className="text-center">{nomor++}</td>
                                        <td>{listUser[key].email}</td>
                                        <td>{listUser[key].nama_lengkap}</td>
                                        <td className="text-center">
                                            <a href={undefined} className="cursor-pointer p-2" id="dropdown_aksi2" data-bs-toggle="dropdown" aria-expanded="false">
                                                <FontAwesomeIcon icon={faEllipsisV} className="text-secondary" aria-hidden="true" />
                                            </a>
                                            <ul className="shadow dropdown-menu px-2 py-3 ms-sm-n4 ms-n5" aria-labelledby="dropdown_aksi2">
                                                <li>
                                                    <button type="button" className="dropdown-item border-radius-md" onClick={() => nagivate(`/admin/ubah_user/${val.id}`)}>
                                                        <FontAwesomeIcon icon={faEdit} className="text-success" style={{ 'width': '14px' }} />&nbsp; Ubah
                                                    </button>
                                                </li>
                                                <li>
                                                    <button type="button" className="dropdown-item border-radius-md" onClick={() => resetPassword(val.id)}>
                                                        <FontAwesomeIcon icon={faSync} className="text-warning" style={{ 'width': '14px' }} />&nbsp; Reset Password
                                                    </button>
                                                </li>
                                                <li>
                                                    <button type="button" className="dropdown-item border-radius-md" onClick={() => hapus(val.id)}>
                                                        <FontAwesomeIcon icon={faTrash} className="text-danger" style={{ 'width': '14px' }} />&nbsp; Hapus
                                                    </button>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td colSpan={7} className="text-center">No data available in table</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default User