import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axios, { axiosPrivate } from '../../api/axios'
import Navbar from '../../components/navbar/Navbar'

const Logout = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const logout = async () => {
        await axiosPrivate.delete('auth/logout').then(() => {
            window.location.href = '/login'
        })
    }

    useEffect(() => {
        logout()
    }, [])
    return <></>
}

export default Logout