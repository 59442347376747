import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faSignOut, faUser } from '@fortawesome/free-solid-svg-icons'
import { Link, useLocation } from 'react-router-dom'
import { BASE_URL } from '../../../api/axios'
import useKonfigurasi from '../../../hooks/useKonfigurasi'
import useAuth from '../../../hooks/useAuth'

const NavbarAdmin = () => {
    const location = useLocation().pathname
    const { auth } = useAuth()
    const { aplikasi } = useKonfigurasi()
    const [logo, setLogo] = useState('')
    const [namaLengkap, setNamaLengkap] = useState('')
    const [gambar, setGambar] = useState('default.png')

    useEffect(() => {
        setNamaLengkap(auth?.nama_lengkap)
        setGambar(auth?.gambar)
    }, [auth])

    useEffect(() => {
        setLogo(aplikasi?.logoAplikasi)
    }, [aplikasi])

    useEffect(() => {
        document.querySelector('#slide-left').addEventListener("click", () => {
            let slide = 100;
            let menuWidth = document.querySelector('.horizontal-main')
            let menuItems = document.querySelector('.side-menu')
            let mainSidemenuWidth = document.querySelector('.main-sidemenu')
            let menuContainerWidth = menuWidth?.offsetWidth - mainSidemenuWidth?.offsetWidth
            let marginLeftValue = Math.ceil(window.getComputedStyle(menuItems).marginLeft.split('px')[0]) + 100;

            if (marginLeftValue < 0) {
                menuItems.style.marginLeft = (parseInt(menuItems.style.marginLeft.split('px')[0]) + slide) + 'px'

                if ((menuWidth?.offsetWidth - menuContainerWidth) < menuItems.scrollWidth) {
                    document.querySelector("#slide-right").classList.remove("d-none");
                }
            } else {
                document.querySelector("#slide-left").classList.add("d-none");
                document.querySelector("#slide-right").classList.remove("d-none");
                document.querySelector("#slide-right").classList.add("d-block");
            }

            if (marginLeftValue >= 0) {
                menuItems.style.marginLeft = 0
            }

            let subNavSub = document.querySelectorAll('.sub-nav-sub');
            subNavSub.forEach((e) => {
                e.style.display = '';
            })
            let subNav = document.querySelectorAll('.nav-sub')
            subNav.forEach((e) => {
                e.style.display = '';
            })
            //
        });

        document.querySelector('#slide-right').addEventListener("click", () => {
            let slide = 100;
            let menuWidth = document.querySelector('.app-sidebar')
            let menuItems = document.querySelector('.side-menu')
            let mainSidemenuWidth = document.querySelector('.main-sidemenu')
            let menuContainerWidth = menuWidth?.offsetWidth - mainSidemenuWidth?.offsetWidth
            let marginLeftValue = Math.ceil(window.getComputedStyle(menuItems).marginLeft.split('px')[0]) - 100;
            let check = menuItems.scrollWidth + (0 - menuWidth?.offsetWidth) + menuContainerWidth;

            if (marginLeftValue > -check) {
                menuItems.style.marginLeft = (parseInt(menuItems.style.marginLeft.split('px')[0]) - slide) + 'px'
                menuItems.style.marginRight = 0
            } else {
                menuItems.style.marginLeft = -check + 'px'
                menuItems.style.marginRight = 0
                document.querySelector('#slide-right').classList.add('d-none');
            }

            if (marginLeftValue != 0) {
                document.querySelector('#slide-left').classList.remove('d-none');
            }
            // to remove dropdown when clicking arrows in horizontal menu
            let subNavSub = document.querySelectorAll('.sub-nav-sub');
            subNavSub.forEach((e) => {
                e.style.display = '';
            })
            let subNav = document.querySelectorAll('.nav-sub')
            subNav.forEach((e) => {
                e.style.display = '';
            })
        });
    }, [])
    return (
        <>
            <div className="header sticky hor-header">
                <div className="main-container container">
                    <div className="d-flex">
                        <a aria-label="Hide Sidebar" className="app-sidebar__toggle" data-bs-toggle="sidebar" href={undefined}></a>
                        <Link className="logo-horizontal" to="/admin">
                            <img src={BASE_URL + '/../img/' + logo} className="header-brand-img desktop-logo" alt="logo" style={{ 'width': '50px' }} />
                        </Link>
                        <div className="d-flex order-lg-2 ms-auto header-right-icons">
                            <button className="navbar-toggler navresponsive-toggler d-lg-none ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent-4" aria-controls="navbarSupportedContent-4" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon fe fe-more-vertical"></span>
                            </button>
                            <div className="navbar navbar-collapse responsive-navbar p-0 navbar-admin">
                                <div className="collapse navbar-collapse" id="navbarSupportedContent-4">
                                    <div className="d-flex order-lg-2 align-items-center">
                                        <div className="dropdown d-flex align-items-center profile-1">
                                            <a href={undefined} data-bs-toggle="dropdown" className="nav-link leading-none d-flex">
                                                <img src={BASE_URL + '/../img/' + gambar} alt="profile-user" className="avatar profile-user brround cover-image" />
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow py-0">
                                                <div className="drop-heading">
                                                    <div className="text-center">
                                                        <img src={BASE_URL + '/../img/' + gambar} alt="profile-user" className="avatar profile-user brround cover-image mb-2" />
                                                        <h5 className="text-dark mb-0 fs-14 fw-semibold">{namaLengkap}</h5>
                                                        <small className="text-muted">Admin</small>
                                                    </div>
                                                </div>
                                                <div className="dropdown-divider m-0"></div>
                                                <Link className="dropdown-item" to="/admin/ubah_password">
                                                    <FontAwesomeIcon icon={faLock} className="dropdown-icon" />
                                                    Ubah Password
                                                </Link>
                                                <Link className="dropdown-item" to="/logout">
                                                    <FontAwesomeIcon icon={faSignOut} className="dropdown-icon" />
                                                    Sign out
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sticky">
                <div className="app-sidebar__overlay" data-bs-toggle="sidebar"></div>
                <div className="app-sidebar horizontal-main">
                    <div className="side-header">
                        <Link className="header-brand1" to="/admin">
                            <img src="https://iuranmasjid.skripsita.online/storage/img/logo.png" className="header-brand-img desktop-logo" alt="logo" style={{ 'width': '50px' }} />
                        </Link>
                    </div>
                    <div className="main-sidemenu is-expanded container">
                        <div className="slide-left disabled active" id="slide-left">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24" height="24" viewBox="0 0 24 24">
                                <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z">
                                </path>
                            </svg>
                        </div>
                        <ul className="side-menu" style={{ 'flexWrap': 'nowrap', 'marginLeft': '0px', 'marginRight': '0px', 'transition': 'margin 700ms' }}>
                            <li className="slide">
                                <Link className={`side-menu__item ${location == '/admin' || location == '/admin/' ? 'active' : ''}`} to="/admin">
                                    <span className="side-menu__label">Dashboard</span>
                                </Link>
                            </li>
                            <li className="sub-category">
                                <h3>Data Master</h3>
                            </li>
                            <li className="slide">
                                <Link className={`side-menu__item ${location.includes('user') ? 'active' : ''}`} to="/admin/user">
                                    <span className="side-menu__label">User</span>
                                </Link>
                            </li>
                            <li className="slide">
                                <Link className={`side-menu__item ${location.includes('carousel') ? 'active' : ''}`} to="/admin/carousel">
                                    <span className="side-menu__label">Carousel</span>
                                </Link>
                            </li>
                            <li className="slide">
                                <Link className={`side-menu__item ${location.includes('mengapa_kami') ? 'active' : ''}`} to="/admin/mengapa_kami">
                                    <span className="side-menu__label">Mengapa Kami</span>
                                </Link>
                            </li>
                            <li className="slide">
                                <Link className={`side-menu__item ${location.includes('gallery') ? 'active' : ''}`} to="/admin/gallery">
                                    <span className="side-menu__label">Gallery</span>
                                </Link>
                            </li>
                            <li className="slide">
                                <Link className={`side-menu__item ${location.includes('services') ? 'active' : ''}`} to="/admin/services">
                                    <span className="side-menu__label">Services</span>
                                </Link>
                            </li>
                            <li className="slide">
                                <Link className={`side-menu__item ${location.includes('partner') ? 'active' : ''}`} to="/admin/partner">
                                    <span className="side-menu__label">Partner</span>
                                </Link>
                            </li>
                            <li className="slide">
                                <Link className={`side-menu__item ${location.includes('faq') ? 'active' : ''}`} to="/admin/faq">
                                    <span className="side-menu__label">FAQ</span>
                                </Link>
                            </li>
                            <li className="slide">
                                <Link className={`side-menu__item ${location.includes('berita') ? 'active' : ''}`} to="/admin/berita">
                                    <span className="side-menu__label">Berita</span>
                                </Link>
                            </li>
                            <li className="slide">
                                <Link className={`side-menu__item ${location.includes('sejarah') ? 'active' : ''}`} to="/admin/sejarah">
                                    <span className="side-menu__label">Sejarah</span>
                                </Link>
                            </li>
                            <li className="slide">
                                <Link className={`side-menu__item ${location.includes('visi_misi_motto') ? 'active' : ''}`} to="/admin/visi_misi_motto">
                                    <span className="side-menu__label">Visi, Misi, dan Motto</span>
                                </Link>
                            </li>
                            <li className="slide">
                                <Link className={`side-menu__item ${location.includes('struktur_organisasi') ? 'active' : ''}`} to="/admin/struktur_organisasi">
                                    <span className="side-menu__label">Struktur Organisasi</span>
                                </Link>
                            </li>
                            <li className="slide">
                                <Link className={`side-menu__item ${location.includes('chat_bot') ? 'active' : ''}`} to="/admin/chat_bot">
                                    <span className="side-menu__label">Chat Bot</span>
                                </Link>
                            </li>
                            <li className="slide">
                                <Link className={`side-menu__item ${location.includes('chat') ? 'active' : ''}`} to="/admin/chat">
                                    <span className="side-menu__label">Chat</span>
                                </Link>
                            </li>
                            <li className="slide">
                                <Link className={`side-menu__item ${location.includes('contact') ? 'active' : ''}`} to="/admin/contact">
                                    <span className="side-menu__label">Contact</span>
                                </Link>
                            </li>
                            <li className="slide">
                                <Link className={`side-menu__item ${location.includes('terms_conditions') ? 'active' : ''}`} to="/admin/terms_conditions">
                                    <span className="side-menu__label">Terms & Conditions</span>
                                </Link>
                            </li>
                            <li className="slide">
                                <Link className={`side-menu__item ${location.includes('privacy_policy') ? 'active' : ''}`} to="/admin/privacy_policy">
                                    <span className="side-menu__label">Privacy Policy</span>
                                </Link>
                            </li>
                            <li className="slide">
                                <Link className={`side-menu__item ${location.includes('ubah_password') ? 'active' : ''}`} to="/admin/ubah_password">
                                    <span className="side-menu__label">Ubah Password</span>
                                </Link>
                            </li>
                            <li className="slide">
                                <Link className={`side-menu__item ${location.includes('pengaturan_website') ? 'active' : ''}`} to="/admin/pengaturan_website">
                                    <span className="side-menu__label">Pengaturan Website</span>
                                </Link>
                            </li>
                            <li className="slide">
                                <a className="side-menu__item" href="/logout">
                                    <span className="side-menu__label">Logout</span>
                                </a>
                            </li>
                        </ul>
                        <div className="slide-right" id="slide-right">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24" height="24" viewBox="0 0 24 24">
                                <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z">
                                </path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NavbarAdmin