import React, { useState, useEffect } from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import useRefreshToken from '../../hooks/useRefreshToken'
import useAuth from '../../hooks/useAuth'

const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [isRefreshed, setIsRefreshed] = useState(false)
    const refresh = useRefreshToken()
    const { auth } = useAuth()
    const nagivate = useNavigate()
    const location = useLocation().pathname

    useEffect(() => {
        if (isRefreshed === false) {
            const verifyRefreshToken = async () => {
                try {
                    setIsRefreshed(true)
                    await refresh()
                } catch (err) {
                    console.error(err)
                } finally {
                    setIsLoading(false)
                }
            }

            !auth?.access_token ? verifyRefreshToken() : setIsLoading(false)
        }
    }, [])

    useEffect(() => {
        if (auth?.access_token === undefined && isLoading == false && location.includes('admin')) {
            nagivate('/logout', { replace: true })
        }
    }, [isLoading])

    return (
        <>

        </>
    )
}

export default PersistLogin