import React from 'react'
import './Home.scss'
import Navbar from '../../components/navbar/Navbar'
import SEO from '../../components/SEO'
import Carousel from '../../components/carousel/Carousel'
import Services from '../../components/services/Services'
import FAQ from '../../components/faq/FAQ'
import Chat from '../../components/chat/Chat'
import ScrollTop from '../../components/scroll_top/ScrollTop'
import Footer from '../../components/footer/Footer'
import Partner from '../../components/partner/Partner'
import Mengapa from '../../components/mengapa/Mengapa'

const Home = () => {
    return (
        <div className="home">
            <SEO title="" description="Hello World" />
            <Navbar />
            <Carousel />
            <Mengapa />
            <Services />
            <Partner />
            <FAQ />
            <Chat />
            <ScrollTop />
            <Footer />
        </div>
    )
}

export default Home