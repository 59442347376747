import React, { useState, useEffect } from 'react'
import LazyLoad from 'react-lazyload'
import axios, { BASE_URL } from '../../api/axios'
import Skeleton from 'react-loading-skeleton'
import './services.scss'

const Services = () => {
    const [listServices, setListServices] = useState(null)
    const getServices = async () => {
        const response = await axios.get('services')
        setListServices(response.data)
    }

    useEffect(() => {
        getServices()
    }, [])

    return (
        <section id="our_solution" className="shadow-sm px-3 py-4">
            <div className="container shadow-sm">
                <div className="title_faq">
                    <img src={require('../../assets/icons/services.png')} alt="" />
                </div>
                <h6 className="text-center fw-500 text-muted mb-5 title_desc">(Solusi Dari Kami)</h6>
                <div className="row">
                    {listServices !== null && listServices.length > 0 ? listServices.map((val, key, arr) => (
                        <div className="col-md-3 col-12 mb-md-0 mb-4 px-md-1 px-0" key={val.id}>
                            <div className="services-item">
                                <LazyLoad>
                                    <img src={`${BASE_URL}/../services/${val.file}`} className="img-fluid w-50 mb-2" alt="" />
                                </LazyLoad>
                                <h6 className="text-center fw-bold mb-2">{val.title}</h6>
                                <p className="text-muted m-0">{val.text}</p>
                            </div>
                        </div>
                    )) : (
                        <>
                            <div className="col-md-3 col-12 mb-md-0 mb-4 px-md-1 px-0" >
                                <div className="services-item row justify-content-center">
                                    <Skeleton containerClassName="services-inner-skeleton d-block w-50 rounded-circle col-12 mb-2" style={{ 'aspectRatio': '1 / 1', 'borderRadius': '50%' }} />
                                    <Skeleton containerClassName="services-inner-skeleton d-block fs-3 fw-bold mb-2" />
                                    <Skeleton containerClassName="services-inner-skeleton d-block m-0" count={3} />
                                </div>
                            </div>
                            <div className="col-md-3 col-12 mb-md-0 mb-4 px-md-1 px-0" >
                                <div className="services-item row justify-content-center">
                                    <Skeleton containerClassName="services-inner-skeleton d-block w-50 rounded-circle col-12 mb-2" style={{ 'aspectRatio': '1 / 1', 'borderRadius': '50%' }} />
                                    <Skeleton containerClassName="services-inner-skeleton d-block fs-3 fw-bold mb-2" />
                                    <Skeleton containerClassName="services-inner-skeleton d-block m-0" count={3} />
                                </div>
                            </div>
                            <div className="col-md-3 col-12 mb-md-0 mb-4 px-md-1 px-0" >
                                <div className="services-item row justify-content-center">
                                    <Skeleton containerClassName="services-inner-skeleton d-block w-50 rounded-circle col-12 mb-2" style={{ 'aspectRatio': '1 / 1', 'borderRadius': '50%' }} />
                                    <Skeleton containerClassName="services-inner-skeleton d-block fs-3 fw-bold mb-2" />
                                    <Skeleton containerClassName="services-inner-skeleton d-block m-0" count={3} />
                                </div>
                            </div>
                            <div className="col-md-3 col-12 mb-md-0 mb-4 px-md-1 px-0" >
                                <div className="services-item row justify-content-center">
                                    <Skeleton containerClassName="services-inner-skeleton d-block w-50 rounded-circle col-12 mb-2" style={{ 'aspectRatio': '1 / 1', 'borderRadius': '50%' }} />
                                    <Skeleton containerClassName="services-inner-skeleton d-block fs-3 fw-bold mb-2" />
                                    <Skeleton containerClassName="services-inner-skeleton d-block m-0" count={3} />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </section>
    )
}

export default Services