import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { BASE_URL } from '../api/axios'
import useKonfigurasi from '../hooks/useKonfigurasi'

export default function SEO({ description, title, siteTitle }) {
    const { aplikasi } = useKonfigurasi()
    const [logo, setLogo] = useState('')
    const [nama, setNama] = useState('')
    useEffect(() => {
        setLogo(aplikasi?.logoAplikasi)

        if (aplikasi !== null) {
            setNama(aplikasi?.namaAplikasi)
        }
    }, [aplikasi])
    return (
        <Helmet>
            <title>{title !== '' ? title + ' | ' + nama : nama}</title>
            <link rel="shortcut icon" href={BASE_URL + '/../img/' + logo} />
            <meta name="description" content={description} />
            <meta property="og:title" content={title !== '' ? title + ' | ' + nama : nama} />
            <meta property="og:description" content={description} />
            <meta property="og:type" content="website" />
            <meta name="twitter:card" content="summary" />,
            <meta name="twitter:title" content={title} />,
            <meta name="twitter:description" content={description} />,
        </Helmet>
    )
}