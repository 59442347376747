import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './chat_info.scss'
// import useKonfigurasi from '../../hooks/useKonfigurasi'
import { faCheck, faMinusCircle, faPaperPlane, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import axios, { BASE_URL, BASE_URL2 } from '../../../../api/axios'
import { io } from 'socket.io-client';
import useAuth from '../../../../hooks/useAuth'
import { useNavigate, useParams } from 'react-router-dom'
import notifSound from '../../../../assets/message.mp3'
import Swal from 'sweetalert2'
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'

const ChatInfo = () => {
    const { id } = useParams()
    const { auth } = useAuth()
    const axiosPrivate = useAxiosPrivate()
    const navigate = useNavigate()
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const [cookieID, setCookieID] = useState('')
    const [message, setMessage] = useState('')
    const [listChat, setListChat] = useState([])
    const [namaChat, setNamaChat] = useState([])
    const [socketAPI, setSocketAPI] = useState(null)
    const [isFinished, setIsFinished] = useState(false)
    const [audio] = useState(new Audio(notifSound))
    const tanggalToDateFormat = (tanggal) => {
        let currentTanggal = new Date(tanggal)
        return currentTanggal.toLocaleDateString("id-ID", options)
    }

    const tanggalToTimeFormat = (tanggal) => {
        let currentTanggal = new Date(tanggal)
        return currentTanggal.toLocaleTimeString("id-ID")
    }

    const getChatInit = async () => {
        try {
            await axios.post('chat/get_chat', {
                where: {
                    "chat.id": id
                }
            }, {
                withCredentials: true
            }).then(async (res) => {
                if (res.data !== null) {
                    setListChat(res.data)
                }
            })
        } catch (err) {
            console.error(err)
        }
    }

    const submitChat = async () => {
        if (socketAPI !== null && cookieID !== '' && message !== '') {
            socketAPI.emit('send_chat', {
                cookie_id: cookieID,
                message: message,
                tipe: 'Admin',
                id_user: auth.id
            })
            setMessage('')
        }
    }

    const handleKeyPressTextarea = (e) => {
        if (e.keyCode === 13 && e.shiftKey) {
            e.preventDefault();
            let start = e.target.selectionStart,
                end = e.target.selectionEnd;
            this.setState(prevState => ({
                message:
                    prevState.message.substring(0, start)
                    + '\n' +
                    prevState.message.substring(end)
            }), () => {
                this.input.selectionStart = this.input.selectionEnd = start + 1;
            })
        } else if (e.keyCode === 13) { // block enter
            e.preventDefault();
        }

    };

    const getChatInfo = async (cookie_id) => {
        await axios.post('chat/find/' + cookie_id, {
            cookie_id: cookie_id
        }).then((res) => {
            if (res !== null) {
                if (res.data?.nama_lengkap !== null && res.data?.nama_lengkap !== '') {
                    setNamaChat(res.data.nama_lengkap)
                } else {
                    setNamaChat('Tidak Diketahui')
                }

                setIsFinished(res.data.is_finished)
            }
        })
    }

    const finishChat = () => {
        Swal.fire({
            title: 'Apa kamu yakin ingin menyelesaikan live chat ini?',
            text: "Kamu tidak akan dapat mengembalikan chat ini",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Saya yakin'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const response = await axiosPrivate.post(`chat/selesai/${id}`)

                if (response.status === 200 && response.data?.message == 'Berhasil diselesaikan') {
                    socketAPI.emit('selesai_chat', { cookie_id: cookieID })
                    setIsFinished(true)
                    Swal.fire(
                        'Berhasil!',
                        'Data chat sudah berhasil diselesaikan.',
                        'success'
                    )
                }
            }
        })
    }

    useEffect(() => {
        const socket_data = io.connect(BASE_URL2)

        axios.post('chat/find/' + id, {
            id: id
        }).then((res) => {
            if (res !== null) {
                setCookieID(res.data.cookie_id)
                getChatInfo(res.data.cookie_id)
                setSocketAPI(socket_data)
            }
        })
    }, [])

    useEffect(() => {
        if (socketAPI !== null) {
            socketAPI.on('connect', () => { })

            socketAPI.emit('join_room', { cookie_id: cookieID })
            getChatInit()

            socketAPI.on('received_chat', (res) => {
                setListChat(prev => [...prev, res])
                audio.play()
            })
        }
    }, [socketAPI])

    useEffect(() => {
        let objectChat = document.querySelector('#ChatBody')
        objectChat.scrollTop = objectChat.scrollHeight
    }, [listChat])

    return (
        <>
            <div className="admin main-content-body-chat h-100 shadow rounded-3 shadow d-flex w-100 mt-4 mb-4">
                <div className="main-chat-header pt-3 d-block d-flex">
                    <div className="main-img-user online">
                        <img alt="avatar" src={BASE_URL + '/../img/default.png'} />
                    </div>
                    <div className="main-chat-msg-name mt-2">
                        <h6>{namaChat}</h6>
                        {isFinished === false ? (
                            <>
                                <span className="dot-label bg-success"> </span>
                                <small className="me-3">online</small>
                            </>
                        ) : (<></>)}
                    </div>
                    <nav className="nav">
                        <button type="button" className="btn" onClick={() => navigate('/admin/chat')}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                        {isFinished === false ? (
                            <button type="button" className="btn" onClick={finishChat}>
                                <FontAwesomeIcon icon={faCheck} />
                            </button>
                        ) : (<></>)}
                    </nav>
                </div>
                <div className="admin main-chat-body flex-2" id="ChatBody">
                    <div className="content-inner">
                        {listChat.length > 0 ? listChat.map((val, index, arr) => (
                            <div className={`media ${val.jenis === 'User' ? 'chat-left' : 'flex-row-reverse chat-right'}`} key={val.id}>
                                <div className="main-img-user online">
                                    <img alt="img" src={`${BASE_URL}/../img/${val.jenis === 'User' ? 'default.png' : (val.user !== null ? val.user?.gambar : 'default.png')}`} />
                                </div>
                                <div className="media-body">
                                    <div className="main-msg-wrapper">
                                        {val.jenis === 'Admin' ? (
                                            <>
                                                <span style={{ fontSize: '12px' }} className="fw-bold">
                                                    {val.user !== null ? val.user?.nama_lengkap : 'Bot'}
                                                </span>
                                                <br />
                                            </>
                                        ) : (<></>)}
                                        {val.pesan}</div>
                                    <div className="main-msg-time-wrapper">
                                        <span>{tanggalToDateFormat(val.createdAt) + ', ' + tanggalToTimeFormat(val.createdAt).substring(0, 5) + ' WIB'}</span>
                                        <a href="#" onClick={(e) => e.preventDefault()}>
                                            <i className="icon ion-android-more-horizontal"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )) : (
                            <></>
                        )}
                    </div>
                </div>
                <div className="main-chat-footer">
                    <textarea className="form-control" placeholder="Type your message here..." rows={1} style={{ resize: 'none' }} onChange={(e) => setMessage(e.target.value)} value={message} {...(isFinished && { disabled: true })}></textarea>
                    <a className="nav-link" data-bs-toggle="tooltip" href="#" onClick={(e) => e.preventDefault()} title="" data-bs-original-title="Attach a File" aria-label="Attach a File">
                        <i className="fe fe-paperclip"></i>
                    </a>
                    <button type="button" className="btn btn-icon btn-primary brround" onClick={submitChat} {...(isFinished && { disabled: true })}>
                        <FontAwesomeIcon icon={faPaperPlane} style={{ fontSize: '14px', width: '14px' }} />
                    </button>
                    <nav className="nav"> </nav>
                </div>
            </div>
        </>
    )

}

export default ChatInfo