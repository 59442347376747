import React, { useState, useEffect } from 'react'
import PartnerList from './PartnerList'
import axios from '../../api/axios'
import Skeleton from 'react-loading-skeleton'
import './partner.scss'

const Partner = () => {
    const [listPartner, setListPartner] = useState(null)
    const getPartner = async () => {
        const response = await axios.get('partner')
        setListPartner(response.data)
    }

    useEffect(() => {
        getPartner()
    }, [])

    return (
        <section id="partner" className="shadow-sm px-3 py-4">
            <div className="container">
                <div className="title_faq">
                    <img src={require('../../assets/icons/PARTNER.png')} alt="" />
                </div>
                <h6 className="text-center fw-500 text-muted mb-5 title_desc">(Rekan Kami)</h6>
                <div className="row align-items-center px-md-5 mx-md-5 justify-content-center">
                    {listPartner !== null && listPartner.length > 0 ? listPartner.map((val, key, arr) => (
                        <PartnerList id={val.id} image={val.file} key={val.id} />
                    )) : (
                        <Skeleton containerClassName="services-inner-skeleton d-block fs-3 fw-bold mb-2" />
                    )}
                </div>
            </div>
        </section>
    )
}

export default Partner