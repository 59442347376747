import React from 'react'
import './image_header.scss'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import LazyLoad from 'react-lazyload'
import { useState, useEffect } from 'react'

const ImageHeader = ({ image }) => {
    const [ImageLocation, setImageLocation] = useState(null)

    useEffect(() => {
        setTimeout(() => {
            setImageLocation(image)
        }, 1500)
    }, [])
    return (
        <section className="image_header mb-4">
            {ImageLocation !== null ? (
                <img src={image} alt="Image Header" />
            ) : (<Skeleton containerClassName="carousel-inner-skeleton d-block" baseColor="#202020" highlightColor="#444" />)}
        </section>
    )
}

export default ImageHeader