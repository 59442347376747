import { createContext, useState } from 'react'

const AplikasiContext = createContext({})

export const AplikasiProvider = ({ children }) => {
    const [aplikasi, setAplikasi] = useState({})

    return (
        <AplikasiContext.Provider value={{ aplikasi, setAplikasi }}>
            {children}
        </AplikasiContext.Provider>
    )
}

export default AplikasiContext