import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'
import Swal from 'sweetalert2'

const TambahServices = () => {
    const navigate = useNavigate()
    const axiosPrivate = useAxiosPrivate()
    const [file, setFile] = useState(null)
    const [title, setTitle] = useState(null)
    const [text, setText] = useState(null)
    const [prioritas, setPrioritas] = useState(null)
    const [fileError, setFileError] = useState('')
    const [titleError, setTitleError] = useState('')
    const [textError, setTextError] = useState('')
    const [prioritasError, setPrioritasError] = useState('')

    const handleFile = (e) => {
        setFile(e.target.files[0])
        setFileError('')
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const form = new FormData()
        form.append('file', file)
        form.append('title', title)
        form.append('text', text)
        form.append('prioritas', prioritas)
        axiosPrivate.post('services/create', form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).catch((err) => {
            if (err.response.data?.errors) {
                if (Array.isArray(err.response.data.errors) === true) {
                    err.response.data.errors.map((val) => {
                        if (val.path === 'prioritas') {
                            setPrioritasError(val.message)
                        } else if (val.path === 'file') {
                            setFileError(val.message)
                        } else if (val.path === 'title') {
                            setTitleError(val.message)
                        } else if (val.path === 'text') {
                            setTextError(val.message)
                        }
                    })
                } else {
                    if (err.response.data.errors.field === 'file') {
                        setFileError(err.response.data.errors.code)
                    }
                }
            }
        }).then((response) => {
            if (response.status === 200 && response.data?.message === 'Berhasil ditambahkan') {
                Swal.fire({
                    title: 'Berhasil!',
                    text: 'Data services sudah berhasil ditambahkan',
                    icon: 'success',
                }).then(() => {
                    navigate('/admin/services')
                })
            }
        })
    }
    return (
        <>
            <div className="page-header">
                <h1 className="page-title">Services</h1>
                <div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="https://iuranmasjid.skripsita.online/admin">Admin</a>
                        </li>
                        <li className="breadcrumb-item">
                            <a href={undefined}>Data Master</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Services
                        </li>
                    </ol>
                </div>
            </div>
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h3 className="card-title">Tambah Services</h3>
                </div>
                <div className="card-body">
                    <form method="POST" encType="mulipart/form-data" onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="file" className="mb-1">File <span className="text-danger">*</span></label>
                            <input type="file" className={`form-control ${fileError !== '' ? 'is-invalid' : ''}`} id="file" name="file" onChange={handleFile} required />
                            <div className="invalid-feedback">{fileError}</div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="title" className="mb-1">Title <span className="text-danger">*</span></label>
                            <input type="text" className={`form-control ${titleError !== '' ? 'is-invalid' : ''}`} id="title" name="title" value={title} onChange={(e) => setTitle(e.target.value)} required />
                            <div className="invalid-feedback">{titleError}</div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="text" className="mb-1">Text <span className="text-danger">*</span></label>
                            <input type="text" className={`form-control ${textError !== '' ? 'is-invalid' : ''}`} id="text" name="text" value={text} onChange={(e) => setText(e.target.value)} required />
                            <div className="invalid-feedback">{textError}</div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="prioritas" className="mb-1">Prioritas <span className="text-danger">*</span></label>
                            <input type="number" min={1} className={`form-control ${prioritasError !== '' ? 'is-invalid' : ''}`} id="prioritas" name="prioritas" value={prioritas} onChange={(e) => setPrioritas(e.target.value)} required />
                            <div className="invalid-feedback">{prioritasError}</div>
                        </div>
                        <div className="clearfix">
                            <div className="float-end">
                                <button type="button" className="btn btn-secondary me-2" onClick={() => navigate(-1)}>Kembali</button>
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default TambahServices