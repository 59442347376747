import { useState, useEffect } from 'react'
import './carousel.scss'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import LazyLoad from 'react-lazyload'
import axios, { BASE_URL } from '../../api/axios'

const Carousel = () => {
    const [listCarousel, setListCarousel] = useState(null)
    const [carouselCaption, setCarouselCaption] = useState(null)
    const getCarousel = async () => {
        const response = await axios.get('carousel')
        setListCarousel(response.data)
    }
    useEffect(() => {
        getCarousel()
        const carouselCaptionTrigger = () => {
            let element = document.querySelector('.carousel-item.active')
            let title = element.getAttribute('data-caption-title')
            let text = element.getAttribute('data-caption-text')

            if (title === '' && text === '') {
                setCarouselCaption(null)
            } else {
                setCarouselCaption({
                    title: title,
                    text: text
                })
            }
        }
        document.querySelector('.carousel').addEventListener('slid.bs.carousel', carouselCaptionTrigger)
    }, []);

    useEffect(() => {
        if (listCarousel) {
            setCarouselCaption({
                title: listCarousel[0].caption_title,
                text: listCarousel[0].captiontext
            })
        }
    }, [listCarousel])

    return (
        <div className="position-relative">
            <div id="carouselExampleIndicators" className={`carousel slide position-relative ${carouselCaption !== null ? 'has-caption' : ''}`} data-bs-ride="carousel">
                <div className="carousel-indicators">
                    {listCarousel ? listCarousel.map((val, key, arr) =>
                    (<button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={key} className={key === 0 ? 'active' : ''} aria-current="true" aria-label="Slide 1" key={listCarousel[key].id}>
                        <div className="indicator-bullet"><div></div></div>
                    </button>)
                    ) : (<Skeleton containerClassName="carousel-indicator-skeleton rounded-pill shadow" baseColor="#B20600" highlightColor="#FF5F00" />)}
                </div>
                <div className="carousel-inner">
                    {listCarousel ? listCarousel.map((val, key, arr) =>
                    (<div className={`carousel-item ${key === 0 ? 'active' : ''}`} key={listCarousel[key].id} data-caption-title={listCarousel[key].caption_title ? listCarousel[key].caption_title : ''} data-caption-text={listCarousel[key].caption_text ? listCarousel[key].caption_text : ''} data-bs-interval="100000">
                        {key === 0 ? (
                            <LazyLoad>
                                <img src={`${BASE_URL}/../carousel/${listCarousel[key].file}`} key={listCarousel[key].id} className="d-block w-100" alt="" />
                            </LazyLoad>)
                            : (<img src={`${BASE_URL}/../carousel/${listCarousel[key].file}`} key={listCarousel[key].id} className="d-block w-100" alt="" />)
                        }
                    </div>)
                    ) : (<Skeleton containerClassName="carousel-inner-skeleton d-block" baseColor="#202020" highlightColor="#444" />)}

                </div>
            </div>
            <div className={`position-absolute carousel-captions text-center ${carouselCaption === null ? 'd-none' : ''}`}>
                <h1>{carouselCaption !== null ? carouselCaption.title : ''}</h1>
                <h4 className="mb-0">{carouselCaption !== null ? carouselCaption.text : ''}</h4>
            </div>
        </div>
    )
}

export default Carousel