import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'
import useAuth from '../../../../hooks/useAuth'
import Swal from 'sweetalert2'

const UbahStrukturOrganisasi = () => {
    const navigate = useNavigate()
    const axiosPrivate = useAxiosPrivate()
    const { auth } = useAuth()
    const { id } = useParams()
    const [file, setFile] = useState(null)
    const [namaLengkap, setNamaLengkap] = useState(null)
    const [jabatan, setJabatan] = useState(null)
    const [instagram, setInstagram] = useState(null)
    const [facebook, setFacebook] = useState(null)
    const [twitter, setTwitter] = useState(null)
    const [linkedin, setLinkedin] = useState(null)
    const [prioritas, setPrioritas] = useState(null)
    const [fileError, setFileError] = useState('')
    const [namaLengkapError, setNamaLengkapError] = useState('')
    const [jabatanError, setJabatanError] = useState('')
    const [instagramError, setInstagramError] = useState('')
    const [facebookError, setFacebookError] = useState('')
    const [twitterError, setTwitterError] = useState('')
    const [linkedinError, setLinkedinError] = useState('')
    const [prioritasError, setPrioritasError] = useState('')

    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                const response = await axiosPrivate.get(`struktur_organisasi/${id}`)

                if (response.data !== null) {
                    const { prioritas, nama_lengkap, jabatan, instagram, facebook, twitter, linkedin } = response.data
                    setNamaLengkap(nama_lengkap)
                    setJabatan(jabatan)
                    setInstagram(instagram)
                    setFacebook(facebook)
                    setTwitter(twitter)
                    setLinkedin(linkedin)
                    setPrioritas(prioritas)
                }
            } else {
                navigate(-1)
            }
        }

        fetchData()
    }, [auth])

    const handleFile = (e) => {
        setFile(e.target.files[0])
        setFileError('')
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const form = new FormData()
        form.append('file', file)
        form.append('nama_lengkap', namaLengkap)
        form.append('jabatan', jabatan)
        form.append('instagram', instagram)
        form.append('facebook', facebook)
        form.append('twitter', twitter)
        form.append('linkedin', linkedin)
        form.append('prioritas', prioritas)
        axiosPrivate.post(`struktur_organisasi/update/${id}`, form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).catch((err) => {
            if (err.response.data?.errors) {
                if (Array.isArray(err.response.data.errors) === true) {
                    err.response.data.errors.map((val) => {
                        if (val.path === 'prioritas') {
                            setPrioritasError(val.message)
                        } else if (val.path === 'file') {
                            setFileError(val.message)
                        } else if (val.path === 'nama_lengkap') {
                            setNamaLengkapError(val.message)
                        } else if (val.path === 'jabatan') {
                            setJabatanError(val.message)
                        } else if (val.path === 'instagram') {
                            setInstagramError(val.message)
                        } else if (val.path === 'facebook') {
                            setFacebookError(val.message)
                        } else if (val.path === 'twitter') {
                            setTwitterError(val.message)
                        } else if (val.path === 'linkedin') {
                            setLinkedinError(val.message)
                        }
                    })
                } else {
                    if (err.response.data.errors.field === 'file') {
                        setFileError(err.response.data.errors.code)
                    }
                }
            }
        }).then((response) => {
            if (response.status === 200 && response.data?.message === 'Berhasil diubah') {

                Swal.fire({
                    title: 'Berhasil!',
                    text: 'Data struktur organisasi sudah berhasil diubah',
                    icon: 'success',
                }).then(() => {
                    navigate('/admin/struktur_organisasi')
                })
            }
        })
    }

    return (
        <>
            <div className="page-header">
                <h1 className="page-title">Struktur Organisasi</h1>
                <div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="https://iuranmasjid.skripsita.online/admin">Admin</a>
                        </li>
                        <li className="breadcrumb-item">
                            <a href={undefined}>Data Master</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Struktur Organisasi
                        </li>
                    </ol>
                </div>
            </div>
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h3 className="card-title">Ubah Struktur Organisasi</h3>
                </div>
                <div className="card-body">
                    <form method="POST" encType="mulipart/form-data" onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="file" className="mb-1">File</label>
                            <input type="file" className={`form-control ${fileError !== '' ? 'is-invalid' : ''}`} id="file" name="file" onChange={handleFile} />
                            <div className="invalid-feedback">{fileError}</div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="nama_lengkap" className="mb-1">Nama Lengkap <span className="text-danger">*</span></label>
                            <input type="text" className={`form-control ${namaLengkapError !== '' ? 'is-invalid' : ''}`} id="nama_lengkap" name="nama_lengkap" value={namaLengkap} onChange={(e) => setNamaLengkap(e.target.value)} required />
                            <div className="invalid-feedback">{namaLengkapError}</div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="jabatan" className="mb-1">Jabatan <span className="text-danger">*</span></label>
                            <input type="text" className={`form-control ${jabatanError !== '' ? 'is-invalid' : ''}`} id="jabatan" name="jabatan" value={jabatan} onChange={(e) => setJabatan(e.target.value)} required />
                            <div className="invalid-feedback">{jabatanError}</div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="instagram" className="mb-1">Instagram</label>
                            <input type="text" className={`form-control ${instagramError !== '' ? 'is-invalid' : ''}`} id="instagram" name="instagram" value={instagram} onChange={(e) => setInstagram(e.target.value)} />
                            <div className="invalid-feedback">{instagramError}</div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="facebook" className="mb-1">Facebook</label>
                            <input type="text" className={`form-control ${facebookError !== '' ? 'is-invalid' : ''}`} id="facebook" name="facebook" value={facebook} onChange={(e) => setFacebook(e.target.value)} />
                            <div className="invalid-feedback">{facebookError}</div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="twitter" className="mb-1">Twitter</label>
                            <input type="text" className={`form-control ${twitterError !== '' ? 'is-invalid' : ''}`} id="twitter" name="twitter" value={twitter} onChange={(e) => setTwitter(e.target.value)} />
                            <div className="invalid-feedback">{twitterError}</div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="linkedin" className="mb-1">LinkedIn</label>
                            <input type="text" className={`form-control ${linkedinError !== '' ? 'is-invalid' : ''}`} id="linkedin" name="linkedin" value={linkedin} onChange={(e) => setLinkedin(e.target.value)} />
                            <div className="invalid-feedback">{linkedinError}</div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="prioritas" className="mb-1">Prioritas <span className="text-danger">*</span></label>
                            <input type="number" min={1} className={`form-control ${prioritasError !== '' ? 'is-invalid' : ''}`} id="prioritas" name="prioritas" value={prioritas} onChange={(e) => setPrioritas(e.target.value)} required />
                            <div className="invalid-feedback">{prioritasError}</div>
                        </div>
                        <div className="clearfix">
                            <div className="float-end">
                                <button type="button" className="btn btn-secondary me-2" onClick={() => navigate(-1)}>Kembali</button>
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default UbahStrukturOrganisasi