import axios from "../api/axios"
import useKonfigurasi from './useKonfigurasi'

const useAplikasiKonfigurasi = () => {
    const { setAplikasi } = useKonfigurasi()

    const konfigurasi = async () => {
        const response_nama_aplikasi = await axios.get(`konfigurasi/?nama_konfigurasi=nama_aplikasi`, {
            withCredentials: true
        })
        const response_logo_aplikasi = await axios.get(`konfigurasi/?nama_konfigurasi=logo_aplikasi`, {
            withCredentials: true
        })
        const response_nomor_ponsel = await axios.get(`konfigurasi/?nama_konfigurasi=nomor_ponsel`, {
            withCredentials: true
        })

        setAplikasi(prev => {
            return { ...prev, namaAplikasi: response_nama_aplikasi.data.nilai, logoAplikasi: response_logo_aplikasi.data.nilai, nomor_ponsel: response_nomor_ponsel.data.nilai }
        })
        return {
            namaAplikasi: response_nama_aplikasi.data.nilai,
            logoAplikasi: response_logo_aplikasi.data.nilai,
            nomor_ponsel: response_nomor_ponsel.data.nilai
        }
    }

    return konfigurasi
}

export default useAplikasiKonfigurasi