import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { useEffect } from 'react'
import './scroll_top.scss'

const ScrollTop = () => {
    const { pathname } = useLocation();
    const toTheTop = () => {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div>
            <FontAwesomeIcon icon={faArrowUp} className="scroll-top" onClick={toTheTop} />
            <FontAwesomeIcon icon={faWhatsapp} className="scroll-top whatsapp" onClick={toTheTop} />
        </div>
    )

}

export default ScrollTop