import React, { useState, useEffect } from 'react'
import axios from '../../api/axios'
import FAQList from './FAQList'
import Skeleton from 'react-loading-skeleton'
import './faq.scss'

const FAQ = () => {
    const [listFAQ, setListFAQ] = useState(null)
    const getFAQ = async () => {
        const response = await axios.get('faq')
        setListFAQ(response.data)
    }

    useEffect(() => {
        getFAQ()
    }, [])

    return (
        <section id="faq" className="shadow-sm px-3 py-4">
            <div className="container">
                <div className="title_faq">
                    <img src={require('../../assets/icons/FAQ.png')} alt="" />
                </div>
                <h6 className="text-center fw-500 text-muted mb-5 title_desc">(Frequently Asked Question)</h6>
                {listFAQ !== null && listFAQ.length > 0 ? listFAQ.map((val, key, arr) => (
                    <FAQList id={val.id} start={key === 0 ? 1 : 'null'} pertanyaan={val.pertanyaan} jawaban={val.jawaban} key={val.id} />
                )) : (
                    <>
                        <Skeleton containerClassName="services-inner-skeleton d-block mb-2" baseColor="#202020" highlightColor="#444" style={{ 'minHeight': '53px' }} />
                        <Skeleton containerClassName="services-inner-skeleton d-block mb-2" baseColor="#202020" highlightColor="#444" style={{ 'minHeight': '53px' }} />
                        <Skeleton containerClassName="services-inner-skeleton d-block mb-2" baseColor="#202020" highlightColor="#444" style={{ 'minHeight': '53px' }} />
                        <Skeleton containerClassName="services-inner-skeleton d-block mb-2" baseColor="#202020" highlightColor="#444" style={{ 'minHeight': '53px' }} />
                    </>
                )}
            </div>
        </section>
    )
}

export default FAQ