import React, { useState, useEffect, useRef } from 'react'
import { faEdit, faEllipsisV, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useNavigate } from 'react-router-dom'
import axios, { BASE_URL } from '../../../../api/axios'
import Swal from 'sweetalert2'
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'
import { Editor } from '@tinymce/tinymce-react';

const MengapaKami = () => {
    let nomor = 1
    const axiosPrivate = useAxiosPrivate()
    const nagivate = useNavigate()
    const mengapaKamiRef = useRef()
    const [mengapaKami, setMengapaKami] = useState(null)
    const [mengapaKamiError, setMengapaKamiError] = useState('')
    const [header, setHeader] = useState('')

    const getMengapaKami = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=mengapa_kami', {
            withCredentials: true
        }).then((response) => {
            if (response?.data?.nilai) {
                setMengapaKami(response.data.nilai)
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        axiosPrivate.post('konfigurasi/update', {
            nama_konfigurasi: 'mengapa_kami',
            nilai: mengapaKamiRef.current.getContent()
        }).catch((err) => {
            if (err.response.data?.errors) {
                if (Array.isArray(err.response.data.errors) === true) {
                    err.response.data.errors.map((val) => {
                        if (val.path === 'nilai') {
                            setMengapaKamiError(val.message)
                        }
                    })
                }
            }
        }).then((response) => {
            if (response.status === 200 && response.data?.message === 'Berhasil diubah') {
                Swal.fire({
                    title: 'Berhasil!',
                    text: 'Data mengapa kami sudah berhasil diubah',
                    icon: 'success',
                })
            }
        })
    }

    useEffect(() => {
        getMengapaKami()
    }, [])

    return (
        <>
            <div className="page-header">
                <h1 className="page-title">Mengapa Kami</h1>
                <div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="https://iuranmasjid.skripsita.online/admin">Admin</a>
                        </li>
                        <li className="breadcrumb-item">
                            <a href={undefined}>Data Master</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Mengapa Kami
                        </li>
                    </ol>
                </div>
            </div>
            <div className="text-wrap mb-4">
                <div className="">
                    <div className="alert alert-info">
                        <div className="d-flex align-items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40" viewBox="0 0 24 24">
                                <path fill="#70a9ee" d="M20.05713,22H3.94287A3.02288,3.02288,0,0,1,1.3252,17.46631L9.38232,3.51123a3.02272,3.02272,0,0,1,5.23536,0L22.6748,17.46631A3.02288,3.02288,0,0,1,20.05713,22Z"></path>
                                <circle cx="12" cy="17" r="1" fill="#1170e4"></circle>
                                <path fill="#1170e4" d="M12,14a1,1,0,0,1-1-1V9a1,1,0,0,1,2,0v4A1,1,0,0,1,12,14Z"></path>
                            </svg>
                            <strong className="ms-2 fw-bolder">Informasi</strong>
                        </div>
                        <hr className="message-inner-separator" />
                        <p> Ini adalah data master. Harap pergunakan data ini dengan baik dan jika sudah tidak diperlukan
                            diperbolehkan untuk dihapus. Namun jika masih diperlukan, maka jangan dihapus karena akan mengakibatkan
                            data lainnya ikut terhapus.</p>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h3 className="card-title">Mengapa Kami</h3>
                </div>
                <div className="card-body">
                    <form method="POST" onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <Editor
                                apiKey="pua31uis8r281btkfuexdw31h7bdoh1orf6pz6oj82fyf9r8"
                                onInit={(evt, editor) => mengapaKamiRef.current = editor}
                                initialValue={mengapaKami}
                                init={{
                                    height: 700,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar: 'undo redo | formatselect | ' +
                                        'bold italic backcolor | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                        'removeformat | numlist | bullist | help',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }}
                            />
                            <div className="invalid-feedback d-block">{mengapaKamiError}</div>
                        </div>
                        <div className="clearfix">
                            <div className="float-end">
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default MengapaKami