import React from 'react'
import LazyLoad from 'react-lazyload'
import './partner.scss'
import { BASE_URL } from '../../api/axios'

const PartnerList = ({ id, image }) => {
    return (
        <div className="col-md-3 col-6 align-middle partner-item mx-auto">
            <LazyLoad>
                <img src={`${BASE_URL}/../partner/${image}`} className="img-fluid" alt="" />
            </LazyLoad>
        </div>
    )
}

export default PartnerList