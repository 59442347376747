import React, { useEffect, useState } from 'react'
import Navbar from '../../components/navbar/Navbar'
import ImageHeader from '../../components/image_header/ImageHeader'
import SEO from '../../components/SEO'
import Chat from '../../components/chat/Chat'
import ScrollTop from '../../components/scroll_top/ScrollTop'
import Footer from '../../components/footer/Footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons'
import axios, { BASE_URL } from '../../api/axios'

const VisiMisiMotto = () => {
    const [visi, setVisi] = useState('')
    const [misi, setMisi] = useState('')
    const [motto, setMotto] = useState('')
    const [header, setHeader] = useState('')
    const getVisi = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=visi').then((response) => {
            setVisi(response.data.nilai)
        })
    }
    const getMisi = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=misi').then((response) => {
            setMisi(response.data.nilai)
        })
    }
    const getMotto = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=motto').then((response) => {
            setMotto(response.data.nilai)
        })
    }
    const getHeader = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=header_visi_misi_motto')
            .then((response) => {
                setHeader(response.data.nilai)
            })
    }

    useEffect(() => {
        getVisi()
        getMisi()
        getMotto()
        getHeader()
    }, [])

    return (
        <div style={{ 'background-color': '#eee' }}>
            <SEO title="Visi, Misi, dan Motto" description="Hello World" />
            <Navbar />
            <ImageHeader image={BASE_URL + '/../img/' + header} />
            <div className="container" id="visi_misi_motto">
                <div className="container">
                    <div className="row mb-4" style={{ 'borderRadius': '2.5rem', 'overflow': 'hidden' }}>
                        <div className="col-md-6 bg-primary">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="title_faq mb-3">
                                        <img src={require('../../assets/icons/VISI.png')} alt="" />
                                    </div>
                                    <div className="d-flex align-item-start justify-content-center mb-2">
                                        <h6 className="fw-500 text-white text-center d-inline fst-italic" dangerouslySetInnerHTML={{ __html: visi }} />
                                    </div>
                                </div>
                                <div className="col-md-12 bg-info">
                                    <div className="container">
                                        <div className="title_faq mb-3">
                                            <img src={require('../../assets/icons/MISI.png')} alt="" />
                                        </div>
                                        <p className="text-white" dangerouslySetInnerHTML={{ __html: misi }}></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 bg-danger d-flex align-items-center justify-content-center">
                            <div>
                                <div className="title_faq mb-3">
                                    <img src={require('../../assets/icons/MOTTO.png')} alt="" />
                                </div>
                                <div className="d-flex align-item-start justify-content-center mb-2">
                                    <FontAwesomeIcon icon={faQuoteLeft} className="text-white me-md-1 fst-italic" />
                                    <h6 className="fw-500 text-white text-center d-inline fst-italic">
                                        {motto}
                                    </h6>
                                    <FontAwesomeIcon icon={faQuoteRight} className="text-white ms-md-2" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Chat />
            <ScrollTop />
            <Footer />
        </div>
    )
}

export default VisiMisiMotto