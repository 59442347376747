import React, { useState, useEffect, useRef } from 'react'
import axios, { BASE_URL } from '../../../../api/axios'
import Swal from 'sweetalert2'
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'
import SEO from '../../../../components/SEO'
import useKonfigurasi from '../../../../hooks/useKonfigurasi'

const PengaturanWebsite = () => {
    let nomor = 1
    const axiosPrivate = useAxiosPrivate()
    const { aplikasi } = useKonfigurasi()
    const aplikasiRef = useRef()
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    const [nomor_ponsel, setNomorPonsel] = useState('')
    const [nomorPonselError, setNomorPonselError] = useState('')
    const [alamat, setAlamat] = useState('')
    const [alamatError, setAlamatError] = useState('')
    const [namaAplikasi, setNamaAplikasi] = useState('')
    const [namaAplikasiError, setNamaAplikasiError] = useState('')
    const [instagram, setInstagram] = useState('')
    const [instagramError, setInstagramError] = useState('')
    const [facebook, setFacebook] = useState('')
    const [facebookError, setFacebookError] = useState('')
    const [twitter, setTwitter] = useState('')
    const [twitterError, setTwitterError] = useState('')
    const [linkedin, setLinkedin] = useState('')
    const [linkedinError, setLinkedinError] = useState('')
    const [youtube, setYoutube] = useState('')
    const [youtubeError, setYoutubeError] = useState('')
    const [logoAplikasi, setLogoAplikasi] = useState('')
    const [fileLogo, setFileLogo] = useState(null)
    const [fileLogoError, setFileLogoError] = useState('')

    const handleFileLogo = (e) => {
        setFileLogo(e.target.files[0])
        setFileLogoError('')
    }

    const handleHeaderSubmit = (e) => {
        e.preventDefault()
        const form = new FormData()
        form.append('file', fileLogo)
        form.append('nama_konfigurasi', 'logo_aplikasi')
        axiosPrivate.post('konfigurasi/image_header', form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).catch((err) => {
            if (err.response.data?.errors) {
                if (Array.isArray(err.response.data.errors) === true) {
                    err.response.data.errors.map((val) => {
                        if (val.path === 'file') {
                            setFileLogoError(val.message)
                        }
                    })
                } else {
                    if (err.response.data.errors.field === 'file') {
                        setFileLogoError(err.response.data.errors.code)
                    }
                }
            }
        }).then((response) => {
            if (response.status === 200 && response.data?.message === 'Berhasil diubah') {
                Swal.fire({
                    title: 'Berhasil!',
                    text: 'Logo aplikasi sudah berhasil diubah',
                    icon: 'success',
                }).then(() => {
                    getLogoAplikasi()
                })
            }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        let berhasil = 0
        await axiosPrivate.post('konfigurasi/update', {
            nama_konfigurasi: 'nama_aplikasi',
            nilai: namaAplikasi
        }).catch((err) => {
            if (err.response.data?.errors) {
                if (Array.isArray(err.response.data.errors) === true) {
                    err.response.data.errors.map((val) => {
                        if (val.path === 'nilai') {
                            setNamaAplikasiError(val.message)
                        }
                    })
                }
            }
        }).then((response) => {
            if (response.status === 200 && response.data?.message === 'Berhasil diubah') {
                getNamaAplikasi()
                berhasil++
            }
        })
        await axiosPrivate.post('konfigurasi/update', {
            nama_konfigurasi: 'email',
            nilai: email
        }).catch((err) => {
            if (err.response.data?.errors) {
                if (Array.isArray(err.response.data.errors) === true) {
                    err.response.data.errors.map((val) => {
                        if (val.path === 'nilai') {
                            setEmailError(val.message)
                        }
                    })
                }
            }
        }).then((response) => {
            if (response.status === 200 && response.data?.message === 'Berhasil diubah') {
                berhasil++
            }
        })
        await axiosPrivate.post('konfigurasi/update', {
            nama_konfigurasi: 'nomor_ponsel',
            nilai: nomor_ponsel
        }).catch((err) => {
            if (err.response.data?.errors) {
                if (Array.isArray(err.response.data.errors) === true) {
                    err.response.data.errors.map((val) => {
                        if (val.path === 'nilai') {
                            setNomorPonselError(val.message)
                        }
                    })
                }
            }
        }).then((response) => {
            if (response.status === 200 && response.data?.message === 'Berhasil diubah') {
                berhasil++
            }
        })
        await axiosPrivate.post('konfigurasi/update', {
            nama_konfigurasi: 'alamat',
            nilai: alamat
        }).catch((err) => {
            if (err.response.data?.errors) {
                if (Array.isArray(err.response.data.errors) === true) {
                    err.response.data.errors.map((val) => {
                        if (val.path === 'nilai') {
                            setAlamatError(val.message)
                        }
                    })
                }
            }
        }).then((response) => {
            if (response.status === 200 && response.data?.message === 'Berhasil diubah') {
                berhasil++
            }
        })
        await axiosPrivate.post('konfigurasi/update', {
            nama_konfigurasi: 'instagram',
            nilai: instagram
        }).catch((err) => {
            if (err.response.data?.errors) {
                if (Array.isArray(err.response.data.errors) === true) {
                    err.response.data.errors.map((val) => {
                        if (val.path === 'nilai') {
                            setInstagramError(val.message)
                        }
                    })
                }
            }
        }).then((response) => {
            if (response.status === 200 && response.data?.message === 'Berhasil diubah') {
                berhasil++
            }
        })
        await axiosPrivate.post('konfigurasi/update', {
            nama_konfigurasi: 'twitter',
            nilai: twitter
        }).catch((err) => {
            if (err.response.data?.errors) {
                if (Array.isArray(err.response.data.errors) === true) {
                    err.response.data.errors.map((val) => {
                        if (val.path === 'nilai') {
                            setTwitterError(val.message)
                        }
                    })
                }
            }
        }).then((response) => {
            if (response.status === 200 && response.data?.message === 'Berhasil diubah') {
                berhasil++
            }
        })
        await axiosPrivate.post('konfigurasi/update', {
            nama_konfigurasi: 'facebook',
            nilai: facebook
        }).catch((err) => {
            if (err.response.data?.errors) {
                if (Array.isArray(err.response.data.errors) === true) {
                    err.response.data.errors.map((val) => {
                        if (val.path === 'nilai') {
                            setFacebookError(val.message)
                        }
                    })
                }
            }
        }).then((response) => {
            if (response.status === 200 && response.data?.message === 'Berhasil diubah') {
                berhasil++
            }
        })
        await axiosPrivate.post('konfigurasi/update', {
            nama_konfigurasi: 'linkedin',
            nilai: linkedin
        }).catch((err) => {
            if (err.response.data?.errors) {
                if (Array.isArray(err.response.data.errors) === true) {
                    err.response.data.errors.map((val) => {
                        if (val.path === 'nilai') {
                            setLinkedinError(val.message)
                        }
                    })
                }
            }
        }).then((response) => {
            if (response.status === 200 && response.data?.message === 'Berhasil diubah') {
                berhasil++
            }
        })
        await axiosPrivate.post('konfigurasi/update', {
            nama_konfigurasi: 'youtube',
            nilai: youtube
        }).catch((err) => {
            if (err.response.data?.errors) {
                if (Array.isArray(err.response.data.errors) === true) {
                    err.response.data.errors.map((val) => {
                        if (val.path === 'nilai') {
                            setYoutubeError(val.message)
                        }
                    })
                }
            }
        }).then((response) => {
            if (response.status === 200 && response.data?.message === 'Berhasil diubah') {
                berhasil++
            }
        })
        if (berhasil === 9) {
            Swal.fire({
                title: 'Berhasil!',
                text: 'Data pengaturan website sudah berhasil diubah',
                icon: 'success',
            })
        } else {
            Swal.fire({
                title: 'Peringatan!',
                text: 'Terjadi permasalahan pada sistem. Mungkin ada beberapa yang berhasil diubah, namun ada beberapa yang gagal diubah',
                icon: 'warning',
            })
        }
    }

    useEffect(() => {
        getEmail()
        getNomorPonsel()
        getAlamat()
        getInstagram()
        getTwitter()
        getFacebook()
        getLinkedin()
        getYoutube()
    }, [])

    useEffect(() => {
        setLogoAplikasi(aplikasi?.logoAplikasi)
        setNamaAplikasi(aplikasi?.namaAplikasi)
    }, [aplikasi])

    const getNamaAplikasi = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=nama_aplikasi', {
            withCredentials: true
        }).then((response) => {
            if (response?.data?.nilai) {
                setNamaAplikasi(response.data.nilai)
            }
        })
    }

    const getLogoAplikasi = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=logo_aplikasi', {
            withCredentials: true
        }).then((response) => {
            if (response?.data?.nilai) {
                setLogoAplikasi(response.data.nilai)
            }
        })
    }

    const getEmail = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=email', {
            withCredentials: true
        }).then((response) => {
            if (response?.data?.nilai) {
                setEmail(response.data.nilai)
            }
        })
    }

    const getNomorPonsel = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=nomor_ponsel', {
            withCredentials: true
        }).then((response) => {
            if (response?.data?.nilai) {
                setNomorPonsel(response.data.nilai)
            }
        })
    }

    const getAlamat = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=alamat', {
            withCredentials: true
        }).then((response) => {
            if (response?.data?.nilai) {
                setAlamat(response.data.nilai)
            }
        })
    }

    const getInstagram = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=instagram', {
            withCredentials: true
        }).then((response) => {
            if (response?.data?.nilai) {
                setInstagram(response.data.nilai)
            }
        })
    }

    const getTwitter = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=twitter', {
            withCredentials: true
        }).then((response) => {
            if (response?.data?.nilai) {
                setTwitter(response.data.nilai)
            }
        })
    }

    const getFacebook = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=facebook', {
            withCredentials: true
        }).then((response) => {
            if (response?.data?.nilai) {
                setFacebook(response.data.nilai)
            }
        })
    }

    const getLinkedin = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=linkedin', {
            withCredentials: true
        }).then((response) => {
            if (response?.data?.nilai) {
                setLinkedin(response.data.nilai)
            }
        })
    }

    const getYoutube = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=youtube', {
            withCredentials: true
        }).then((response) => {
            if (response?.data?.nilai) {
                setYoutube(response.data.nilai)
            }
        })
    }

    return (
        <>
            <SEO title="Pengaturan Website" description="Hello World" />
            <div className="page-header">
                <h1 className="page-title">Terms and Conditions</h1>
                <div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="https://iuranmasjid.skripsita.online/admin">Admin</a>
                        </li>
                        <li className="breadcrumb-item">
                            <a href={undefined}>Data Master</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Terms and Conditions
                        </li>
                    </ol>
                </div>
            </div>
            <div className="text-wrap mb-4">
                <div className="">
                    <div className="alert alert-info">
                        <div className="d-flex align-items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40" viewBox="0 0 24 24">
                                <path fill="#70a9ee" d="M20.05713,22H3.94287A3.02288,3.02288,0,0,1,1.3252,17.46631L9.38232,3.51123a3.02272,3.02272,0,0,1,5.23536,0L22.6748,17.46631A3.02288,3.02288,0,0,1,20.05713,22Z"></path>
                                <circle cx="12" cy="17" r="1" fill="#1170e4"></circle>
                                <path fill="#1170e4" d="M12,14a1,1,0,0,1-1-1V9a1,1,0,0,1,2,0v4A1,1,0,0,1,12,14Z"></path>
                            </svg>
                            <strong className="ms-2 fw-bolder">Informasi</strong>
                        </div>
                        <hr className="message-inner-separator" />
                        <p> Ini adalah data master. Harap pergunakan data ini dengan baik dan jika sudah tidak diperlukan
                            diperbolehkan untuk dihapus. Namun jika masih diperlukan, maka jangan dihapus karena akan mengakibatkan
                            data lainnya ikut terhapus.</p>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h3 className="card-title">Logo Aplikasi</h3>
                </div>
                <div className="card-body">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img src={BASE_URL + '/../img/' + logoAplikasi} alt="Image Header" />
                        </div>
                        <div className="col-md-6">
                            <form method="POST" encType="multipart/form-data" onSubmit={handleHeaderSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="file">File Image Header</label>
                                    <input type="file" className={`form-control ${fileLogoError !== '' ? 'is-invalid' : ''}`} id="file" name="file" onChange={handleFileLogo} required />
                                    <div className="invalid-feedback">{fileLogoError}</div>
                                </div>
                                <button type="submit" className="btn btn-primary w-100 rounded-pill">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h3 className="card-title">Pengaturan Website</h3>
                </div>
                <div className="card-body">
                    <form method="POST" onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="namaAplikasi" className="mb-1">Nama Aplikasi <span className="text-danger">*</span></label>
                            <input type="text" className={`form-control ${namaAplikasiError !== '' ? 'is-invalid' : ''}`} id="namaAplikasi" name="namaAplikasi" value={namaAplikasi} onChange={(e) => setNamaAplikasi(e.target.value)} required />
                            <div className="invalid-feedback d-block">{namaAplikasiError}</div>
                        </div>
                        <div className="row">
                            <div className="mb-3 col-md-4">
                                <label htmlFor="email" className="mb-1">Email <span className="text-danger">*</span></label>
                                <input type="text" className={`form-control ${emailError !== '' ? 'is-invalid' : ''}`} id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                <div className="invalid-feedback d-block">{emailError}</div>
                            </div>
                            <div className="mb-3 col-md-4">
                                <label htmlFor="nomor_ponsel" className="mb-1">Nomor Ponsel <span className="text-danger">*</span></label>
                                <div className="input-group">
                                    <span className="input-group-text">+62</span>
                                    <input type="number" className={`form-control ${nomorPonselError !== '' ? 'is-invalid' : ''}`} id="nomor_ponsel" name="nomor_ponsel" value={nomor_ponsel} onChange={(e) => setNomorPonsel(e.target.value)} required />
                                    <div className="invalid-feedback">{nomorPonselError}</div>
                                </div>
                            </div>
                            <div className="mb-3 col-md-4">
                                <label htmlFor="alamat" className="mb-1">Alamat <span className="text-danger">*</span></label>
                                <input type="text" className={`form-control ${alamatError !== '' ? 'is-invalid' : ''}`} id="alamat" name="alamat" value={alamat} onChange={(e) => setAlamat(e.target.value)} required />
                                <div className="invalid-feedback d-block">{alamatError}</div>
                            </div>
                            <div className="mb-3 col-md-4">
                                <label htmlFor="instagram" className="mb-1">Instagram</label>
                                <input type="text" className={`form-control ${instagramError !== '' ? 'is-invalid' : ''}`} id="instagram" name="instagram" value={instagram} onChange={(e) => setInstagram(e.target.value)} />
                                <div className="invalid-feedback d-block">{instagramError}</div>
                            </div>
                            <div className="mb-3 col-md-4">
                                <label htmlFor="twitter" className="mb-1">Twitter</label>
                                <input type="text" className={`form-control ${twitterError !== '' ? 'is-invalid' : ''}`} id="twitter" name="twitter" value={twitter} onChange={(e) => setTwitter(e.target.value)} />
                                <div className="invalid-feedback d-block">{twitterError}</div>
                            </div>
                            <div className="mb-3 col-md-4">
                                <label htmlFor="facebook" className="mb-1">Facebook</label>
                                <input type="text" className={`form-control ${facebookError !== '' ? 'is-invalid' : ''}`} id="facebook" name="facebook" value={facebook} onChange={(e) => setFacebook(e.target.value)} />
                                <div className="invalid-feedback d-block">{facebookError}</div>
                            </div>
                            <div className="mb-3 col-md-4">
                                <label htmlFor="linkedin" className="mb-1">LinkedIn</label>
                                <input type="text" className={`form-control ${linkedinError !== '' ? 'is-invalid' : ''}`} id="linkedin" name="linkedin" value={linkedin} onChange={(e) => setLinkedin(e.target.value)} />
                                <div className="invalid-feedback d-block">{linkedinError}</div>
                            </div>
                            <div className="mb-3 col-md-4">
                                <label htmlFor="youtube" className="mb-1">Youtube</label>
                                <input type="text" className={`form-control ${youtubeError !== '' ? 'is-invalid' : ''}`} id="youtube" name="youtube" value={youtube} onChange={(e) => setYoutube(e.target.value)} />
                                <div className="invalid-feedback d-block">{youtubeError}</div>
                            </div>
                        </div>
                        <div className="clearfix">
                            <div className="float-end">
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default PengaturanWebsite