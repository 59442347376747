import React, { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import PersistAplikasi from "./PersistAplikasi";
import PersistLogin from "./PersistLogin";

const PersistAll = () => {
    return (
        <>
            <PersistAplikasi />
            <PersistLogin />
            <Outlet />
        </>
    )
}

export default PersistAll