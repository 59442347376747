import React, { useEffect, useState, useRef } from 'react'
import Navbar from '../../components/navbar/Navbar'
import ImageHeader from '../../components/image_header/ImageHeader'
import SEO from '../../components/SEO'
import Chat from '../../components/chat/Chat'
import ScrollTop from '../../components/scroll_top/ScrollTop'
import Footer from '../../components/footer/Footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faTwitter, faInstagram, faLinkedin, faYoutube, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import axios, { BASE_URL } from '../../api/axios'
import Swal from 'sweetalert2'

const Contact = () => {
    const [header, setHeader] = useState('')
    const [first_name, setFirstName] = useState('')
    const [last_name, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [nomor_ponsel, setNomorPonsel] = useState('')
    const [message, setMessage] = useState('')
    const [firstNameError, setFirstNameError] = useState('')
    const [lastNameError, setLastNameError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [nomorPonselError, setNomorPonselError] = useState('')
    const [messageError, setMessageError] = useState('')
    const messageRef = useRef()
    const [emailAplikasi, setEmailAplikasi] = useState('')
    const [emailAplikasiError, setEmailAplikasiError] = useState('')
    const [nomorPonselAplikasi, setNomorPonselAplikasi] = useState('')
    const [nomorPonselAplikasiError, setNomorPonselAplikasiError] = useState('')
    const [alamatAplikasi, setAlamatAplikasi] = useState('')
    const [alamatAplikasiError, setAlamatAplikasiError] = useState('')
    const [instagramAplikasi, setInstagramAplikasi] = useState('')
    const [instagramAplikasiError, setInstagramAplikasiError] = useState('')
    const [facebookAplikasi, setFacebookAplikasi] = useState('')
    const [facebookAplikasiError, setFacebookAplikasiError] = useState('')
    const [twitterAplikasi, setTwitterAplikasi] = useState('')
    const [twitterAplikasiError, setTwitterAplikasiError] = useState('')
    const [linkedinAplikasi, setLinkedinAplikasi] = useState('')
    const [linkedinAplikasiError, setLinkedinAplikasiError] = useState('')
    const [youtubeAplikasi, setYoutubeAplikasi] = useState('')
    const [youtubeAplikasiError, setYoutubeAplikasiError] = useState('')
    const getHeader = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=header_contact')
            .then((response) => {
                setHeader(response.data.nilai)
            })
    }

    const escapeHtml = (text) => {
        let map = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#039;'
        };

        return text.replace(/[&<>"']/g, function (m) { return map[m]; });
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        axios.post('contact/create', {
            first_name: escapeHtml(first_name),
            last_name: escapeHtml(last_name),
            email: email,
            nomor_ponsel: nomor_ponsel,
            message: escapeHtml(message),
        }).catch((err) => {
            if (err.response.data?.errors) {
                if (Array.isArray(err.response.data.errors) === true) {
                    err.response.data.errors.map((val) => {
                        if (val.path === 'first_name') {
                            setFirstNameError(val.message)
                        } else if (val.path === 'last_name') {
                            setLastNameError(val.message)
                        } else if (val.path === 'email') {
                            setEmailError(val.message)
                        } else if (val.path === 'nomor_ponsel') {
                            setNomorPonselError(val.message)
                        } else if (val.path === 'message') {
                            setMessageError(val.message)
                        }
                    })
                }
            }
        }).then((response) => {
            if (response.status === 200 && response.data?.message === 'Berhasil ditambahkan') {
                Swal.fire({
                    title: 'Berhasil!',
                    text: 'Form contact sudah berhasil dikirimkan! Anda akan menerima pesan balasan melalui Email apabila sudah direspon oleh Admin',
                    icon: 'success',
                }).then(() => {
                    setFirstName('')
                    setLastName('')
                    setEmail('')
                    setNomorPonsel('')
                    setMessage('')
                    messageRef.current.value = ''
                })
            }
        })
    }

    const getEmailAplikasi = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=email', {
            withCredentials: true
        }).then((response) => {
            if (response?.data?.nilai) {
                setEmailAplikasi(response.data.nilai)
            }
        })
    }

    const getNomorPonselAplikasi = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=nomor_ponsel', {
            withCredentials: true
        }).then((response) => {
            if (response?.data?.nilai) {
                setNomorPonselAplikasi(response.data.nilai)
            }
        })
    }

    const getAlamatAplikasi = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=alamat', {
            withCredentials: true
        }).then((response) => {
            if (response?.data?.nilai) {
                setAlamatAplikasi(response.data.nilai)
            }
        })
    }

    const getInstagramAplikasi = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=instagram', {
            withCredentials: true
        }).then((response) => {
            if (response?.data?.nilai) {
                setInstagramAplikasi(response.data.nilai)
            }
        })
    }

    const getTwitterAplikasi = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=twitter', {
            withCredentials: true
        }).then((response) => {
            if (response?.data?.nilai) {
                setTwitterAplikasi(response.data.nilai)
            }
        })
    }

    const getFacebookAplikasi = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=facebook', {
            withCredentials: true
        }).then((response) => {
            if (response?.data?.nilai) {
                setFacebookAplikasi(response.data.nilai)
            }
        })
    }

    const getLinkedinAplikasi = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=linkedin', {
            withCredentials: true
        }).then((response) => {
            if (response?.data?.nilai) {
                setLinkedinAplikasi(response.data.nilai)
            }
        })
    }

    const getYoutubeAplikasi = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=youtube', {
            withCredentials: true
        }).then((response) => {
            if (response?.data?.nilai) {
                setYoutubeAplikasi(response.data.nilai)
            }
        })
    }

    useEffect(() => {
        getHeader()
        getEmailAplikasi()
        getNomorPonselAplikasi()
        getAlamatAplikasi()
        getInstagramAplikasi()
        getTwitterAplikasi()
        getFacebookAplikasi()
        getLinkedinAplikasi()
        getYoutubeAplikasi()
    }, [])

    return (
        <div style={{ 'background': '#eee' }}>
            <SEO title="Contact" description="Hello World" />
            <Navbar />
            <ImageHeader image={BASE_URL + '/../img/' + header} />
            <div className="container">
                <div className="bg-white mb-4" style={{ 'borderRadius': '12px', 'padding': '5px' }}>
                    <div className="row">
                        <div className="col-md-5 d-flex">
                            <div className="text-white py-4 px-4 h-100 position-relative" style={{ 'background': '#3e1f92', 'borderRadius': '7px' }}>
                                <h5 className="fw-bold">Contact Information</h5>
                                <p className="mb-4" style={{ 'color': 'rgba(255,255,255,0.8)' }}>Fill up the form and our team will get back to you within 24 hours</p>
                                <table className="mb-5">
                                    <tbody>
                                        <tr>
                                            <td className="text-center py-2"><FontAwesomeIcon className="fa-2x" icon={faWhatsapp} /></td>
                                            <td width={10}></td>
                                            <td><a href={`wa.me/62${nomorPonselAplikasi}`} className="text-decoration-none text-white" target="_blank" rel="noreferrer">{'0' + nomorPonselAplikasi}</a></td>
                                        </tr>
                                        <tr>
                                            <td className="text-center py-2"><FontAwesomeIcon className="fa-2x" icon={faEnvelope} /></td>
                                            <td width={10}></td>
                                            <td><a href={`mailto:${emailAplikasi}`} className="text-decoration-none text-white" target="_blank" rel="noreferrer">{emailAplikasi}</a></td>
                                        </tr>
                                        <tr>
                                            <td className="text-center py-2"><FontAwesomeIcon className="fa-2x" icon={faMapMarkerAlt} /></td>
                                            <td width={10}></td>
                                            <td>{alamatAplikasi}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="position-absolute" style={{ 'bottom': '20px' }}>
                                    {facebookAplikasi !== null && facebookAplikasi !== 'null' && facebookAplikasi !== '' ? (
                                        <a href={`https://www.facebook.com/${facebookAplikasi !== null && facebookAplikasi !== 'null' ? facebookAplikasi : ''}`} className="text-decoration-none text-light me-3" target="_blank" rel="noreferrer"><FontAwesomeIcon className="fa-2x" icon={faFacebook} /></a>
                                    ) : ''}
                                    {twitterAplikasi !== null && twitterAplikasi !== 'null' && twitterAplikasi !== '' ? (
                                        <a href={`https://www.twitter.com/${twitterAplikasi !== null && twitterAplikasi !== 'null' ? twitterAplikasi : ''}`} className="text-decoration-none text-light me-3" target="_blank" rel="noreferrer"><FontAwesomeIcon className="fa-2x" icon={faTwitter} /></a>
                                    ) : ''}
                                    {instagramAplikasi !== null && instagramAplikasi !== 'null' && instagramAplikasi !== '' ? (
                                        <a href={`https://www.instagram.com/${instagramAplikasi !== null && instagramAplikasi !== 'null' ? instagramAplikasi : ''}`} className="text-decoration-none text-light me-3" target="_blank" rel="noreferrer"><FontAwesomeIcon className="fa-2x" icon={faInstagram} /></a>
                                    ) : ''}
                                    {linkedinAplikasi !== null && linkedinAplikasi !== 'null' && linkedinAplikasi !== '' ? (
                                        <a href={`https://www.linkedin.com/in/${linkedinAplikasi !== null && linkedinAplikasi !== 'null' ? linkedinAplikasi : ''}`} className="text-decoration-none text-light me-3" target="_blank" rel="noreferrer"><FontAwesomeIcon className="fa-2x" icon={faLinkedin} /></a>
                                    ) : ''}
                                    {youtubeAplikasi !== null && youtubeAplikasi !== 'null' && youtubeAplikasi !== '' ? (
                                        <a href={`https://www.youtube.com/channel/${youtubeAplikasi !== null && youtubeAplikasi !== 'null' ? youtubeAplikasi : ''}`} className="text-decoration-none text-light me-3" target="_blank" rel="noreferrer"><FontAwesomeIcon className="fa-2x" icon={faYoutube} /></a>
                                    ) : ''}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7 px-3 py-4">
                            <div className="title_faq mb-0">
                                <img src={require('../../assets/icons/contact.png')} alt="" />
                            </div>
                            <form method="POST" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="first_name" className="mb-1">First Name <span className="text-danger">*</span></label>
                                        <input type="text" className={`form-control ${first_name !== '' ? 'is-invalid' : ''}`} id="first_name" name="first_name" value={first_name} onChange={(e) => setFirstName(e.target.value)} required />
                                        <div className="invalid-feedback">{firstNameError}</div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="last_name" className="mb-1">Last Name <span className="text-danger">*</span></label>
                                        <input type="text" className={`form-control ${last_name !== '' ? 'is-invalid' : ''}`} id="last_name" name="last_name" value={last_name} onChange={(e) => setLastName(e.target.value)} required />
                                        <div className="invalid-feedback">{lastNameError}</div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="email" className="mb-1">Email Address <span className="text-danger">*</span></label>
                                        <input type="email" className={`form-control ${email !== '' ? 'is-invalid' : ''}`} id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                        <div className="invalid-feedback">{emailError}</div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="nomor_ponsel" className="mb-1">Nomor Ponsel <span className="text-danger">*</span></label>
                                        <div className="input-group">
                                            <span className="input-group-text">+62</span>
                                            <input type="number" className={`form-control ${nomor_ponsel !== '' ? 'is-invalid' : ''}`} id="nomor_ponsel" name="nomor_ponsel" value={nomor_ponsel} onChange={(e) => setNomorPonsel(e.target.value)} required />
                                            <div className="invalid-feedback">{nomorPonselError}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="message" className="mb-1">Message <span className="text-danger">*</span></label>
                                        <textarea className={`form-control ${message !== '' ? 'is-invalid' : ''}`} id="message" name="message" onChange={(e) => setMessage(e.target.value)} ref={messageRef} required></textarea>
                                        <div className="invalid-feedback">{messageError}</div>
                                    </div>
                                    <div className="clearfix">
                                        <button type="submit" className="btn btn-primary px-4 rounded-pill float-end">Send</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Chat />
            <ScrollTop />
            <Footer />
        </div>
    )
}

export default Contact