import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import Cookies from 'js-cookie'
import './chat.scss'
// import useKonfigurasi from '../../hooks/useKonfigurasi'
import { faMinusCircle, faPaperPlane, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import axios, { BASE_URL, BASE_URL2 } from '../../api/axios'
import { io } from 'socket.io-client';
import useAuth from '../../hooks/useAuth'
import notifSound from '../../assets/message.mp3'

const Chat = () => {
    // const { aplikasi } = useKonfigurasi()
    // const [nomor_ponsel, setNomorPonsel] = useState('')
    const { auth } = useAuth()
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const [message, setMessage] = useState('')
    const [isChat, setIsChat] = useState(false)
    const [isChatStarted, setIsChatStarted] = useState(false)
    const [listChat, setListChat] = useState([])
    const [namaChat, setNamaChat] = useState([])
    const [logoChat, setLogoChat] = useState([])
    const [socketAPI, setSocketAPI] = useState(null)
    const [audio] = useState(new Audio(notifSound))
    const tanggalToDateFormat = (tanggal) => {
        let currentTanggal = new Date(tanggal)
        return currentTanggal.toLocaleDateString("id-ID", options)
    }

    const tanggalToTimeFormat = (tanggal) => {
        let currentTanggal = new Date(tanggal)
        return currentTanggal.toLocaleTimeString("id-ID")
    }

    const getChatInit = async () => {
        try {
            await axios.post('chat/get_chat', {
                where: {
                    "chat.cookie_id": Cookies.get('chat_cookie_id')
                }
            }, {
                withCredentials: true
            }).then(async (res) => {
                if (res.data !== null) {
                    setListChat(res.data)
                }
            })
        } catch (err) {
            console.error(err)
        }
    }

    const openChat = () => {
        setIsChat(true)
    }

    const startChat = async () => {
        if (Cookies.get('chat_cookie_id') === undefined && socketAPI !== null) {
            socketAPI.emit('start_chat')
        }
    }

    const submitChat = async () => {
        if (socketAPI !== null && Cookies.get('chat_cookie_id') !== undefined && message !== '') {
            socketAPI.emit('send_chat', {
                cookie_id: Cookies.get('chat_cookie_id'),
                message: message
            })
            setMessage('')
        }
    }

    const handleKeyPressTextarea = (e) => {
        if (e.keyCode === 13 && e.shiftKey) {
            e.preventDefault();
            let start = e.target.selectionStart,
                end = e.target.selectionEnd;
            this.setState(prevState => ({
                message:
                    prevState.message.substring(0, start)
                    + '\n' +
                    prevState.message.substring(end)
            }), () => {
                this.input.selectionStart = this.input.selectionEnd = start + 1;
            })
        } else if (e.keyCode === 13) { // block enter
            e.preventDefault();
        }

    };

    const getChatInfo = async () => {
        await axios.post('chat/find/' + Cookies.get('chat_cookie_id'), {
            cookie_id: Cookies.get('chat_cookie_id')
        }).then((res) => {
            if (res !== null) {
                if (res.data?.is_finished === false) {
                    if (res.data?.user !== null) {
                        setNamaChat(res.data.user.nama_lengkap)
                        setLogoChat(res.data.user.gambar)
                    } else {
                        setNamaChat('Bot')
                        setLogoChat('default.png')
                    }
                } else {
                    setIsChat(false)
                    setIsChatStarted(false)
                    Cookies.remove('chat_cookie_id', { path: '/' })
                    setListChat([])
                }
            }
        })
    }

    // useEffect(() => {
    //     if (aplikasi?.nomor_ponsel) {
    //         setNomorPonsel(aplikasi.nomor_ponsel)
    //     }
    // }, [])

    useEffect(() => {
        const socket_data = io.connect(BASE_URL2)
        setSocketAPI(socket_data)

        if (Cookies.get('chat_cookie_id') !== undefined) {
            setIsChatStarted(true)
            getChatInfo()
        }

        return () => { }
    }, [])

    useEffect(() => {
        if (socketAPI !== null) {
            socketAPI.on('connect', () => { })

            if (Cookies.get('chat_cookie_id') !== undefined) {
                socketAPI.emit('join_room', { cookie_id: Cookies.get('chat_cookie_id') })
                getChatInit()
            }

            socketAPI.on('get_cookie_id', ({ cookie_id }) => {
                Cookies.set('chat_cookie_id', cookie_id, {
                    expires: 365,
                    path: '/'
                })
                setIsChatStarted(true)
                socketAPI.emit('join_room', { cookie_id: cookie_id })
                getChatInfo()
            })

            socketAPI.on('received_chat', (res) => {
                setListChat(prev => [...prev, res])
                audio.play()
            })

            socketAPI.on('change_admin', () => {
                getChatInfo()
            })

            socketAPI.on('end_chat', () => {
                getChatInfo()
                setIsChatStarted(false)
                setListChat([])
                setIsChat(false)
            })
        }
    }, [socketAPI])

    useEffect(() => {
        let objectChat = document.querySelector('#ChatBody')
        objectChat.scrollTop = objectChat.scrollHeight
    }, [listChat])

    return (
        <>
            {/* <a href={`https://wa.me/62${nomor_ponsel}?text=Saya%20ingin%20bertanya`} className="d-block shadow" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faWhatsapp} className="chat" />
            </a> */}
            <button type="button" className={`shadow ${isChat === false && Object.keys(auth).length === 0 ? 'd-block' : 'd-none'}`} target="_blank" rel="noreferrer" onClick={() => openChat()}>
                <FontAwesomeIcon icon={faWhatsapp} className="chat" />
            </button>
            <div className={`user main-content-body main-content-body-chat h-100 shadow rounded-3 shadow ${isChat === true && isChatStarted === false ? 'd-flex' : 'd-none'}`} style={{
                minWidth: '400px'
            }}>
                <button type="button" className="btn position-absolute" style={{ right: '5px' }} onClick={() => setIsChat(false)}>
                    <FontAwesomeIcon icon={faMinusCircle} />
                </button>
                <div className="d-flex align-items-center justify-content-center" style={{ flex: 1 }}>
                    <div className="text-center d-block w-100 px-5">
                        <FontAwesomeIcon icon={faTimesCircle} style={{ width: '50px', fontSize: '50px' }} className="text-danger mb-2" />
                        <h6 className="fw-bold mb-4">Belum Ada Pesan!</h6>
                        <button type="button" className="w-100 rounded-pill btn btn-primary" onClick={() => startChat()}>Mulai Pesan</button>
                    </div>
                </div>
            </div>
            <div className={`user main-content-body main-content-body-chat h-100 shadow rounded-3 shadow ${isChat === true && isChatStarted === true ? 'd-flex' : 'd-none'}`}>
                <div className="main-chat-header pt-3 d-block d-flex">
                    <div className="main-img-user online">
                        <img alt="avatar" src={BASE_URL + '/../img/' + logoChat} />
                    </div>
                    <div className="main-chat-msg-name mt-2">
                        <h6>{namaChat}</h6>
                        <span className="dot-label bg-success"> </span>
                        <small className="me-3">online</small>
                    </div>
                    <nav className="nav">
                        <button type="button" className="btn" onClick={() => setIsChat(false)}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </nav>
                </div>
                <div className="main-chat-body flex-2" id="ChatBody">
                    <div className="content-inner">
                        {listChat.length > 0 ? listChat.map((val, index, arr) => (
                            <div className={`media ${val.jenis === 'Admin' ? 'chat-left' : 'flex-row-reverse chat-right'}`} key={val.id}>
                                <div className="main-img-user online">
                                    <img alt="img" src={`${BASE_URL}/../img/${val.jenis === 'User' ? 'default.png' : (val.user !== null ? val.user?.gambar : 'default.png')}`} />
                                </div>
                                <div className="media-body">
                                    <div className="main-msg-wrapper">
                                        {val.jenis === 'Admin' ? (
                                            <>
                                                <span style={{ fontSize: '12px' }} className="fw-bold">
                                                    {val.user !== null ? val.user?.nama_lengkap : 'Bot'}
                                                </span>
                                                <br />
                                            </>
                                        ) : (<></>)}
                                        {val.pesan}</div>
                                    <div className="main-msg-time-wrapper">
                                        <span>{tanggalToDateFormat(val.createdAt) + ', ' + tanggalToTimeFormat(val.createdAt).substring(0, 5) + ' WIB'}</span>
                                        <a href="#" onClick={(e) => e.preventDefault()}>
                                            <i className="icon ion-android-more-horizontal"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )) : (
                            <></>
                        )}
                    </div>
                </div>
                <div className="main-chat-footer">
                    <textarea className="form-control" placeholder="Type your message here..." rows={1} style={{ resize: 'none' }} onChange={(e) => setMessage(e.target.value)} value={message}></textarea>
                    <a className="nav-link" data-bs-toggle="tooltip" href="#" onClick={(e) => e.preventDefault()} title="" data-bs-original-title="Attach a File" aria-label="Attach a File">
                        <i className="fe fe-paperclip"></i>
                    </a>
                    <button type="button" className="btn btn-icon btn-primary brround" onClick={submitChat}>
                        <FontAwesomeIcon icon={faPaperPlane} style={{ fontSize: '14px', width: '14px' }} />
                    </button>
                    <nav className="nav"> </nav>
                </div>
            </div>
        </>
    )

}

export default Chat