import React, { useEffect } from 'react'
import { useNavigate, Outlet, Link, useLocation } from 'react-router-dom'
import NavbarAdmin from './components/NavbarAdmin'

const Admin = ({ title = '' }) => {
    const navigate = useNavigate()
    require('./admin.scss')

    return (
        <>
            <div className="page-admin">
                <div className="page-main">
                    <NavbarAdmin />
                    <div className="main-content mt-0 hor-content">
                        <div className="main-container container">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Admin