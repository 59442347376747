import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'
import useAuth from '../../../../hooks/useAuth'
import Swal from 'sweetalert2'

const UbahPassword = () => {
    const navigate = useNavigate()
    const axiosPrivate = useAxiosPrivate()
    const { auth } = useAuth()
    const [passwordLama, setPasswordLama] = useState('')
    const [passwordBaru, setPasswordBaru] = useState('')
    const [repasswordBaru, setRepasswordBaru] = useState('')
    const [passwordLamaError, setPasswordLamaError] = useState('')
    const [passwordBaruError, setPasswordBaruError] = useState('')
    const [repasswordBaruError, setRepasswordBaruError] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault()
        axiosPrivate.post('auth/ubah_password', {
            passwordLama: passwordLama,
            passwordBaru: passwordBaru,
            repasswordBaru: repasswordBaru
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).catch((err) => {
            if (err.response.data?.errors) {
                if (Array.isArray(err.response.data.errors) === true) {
                    err.response.data.errors.map((val) => {
                        console.log(val)
                        if (val.path === 'passwordLama') {
                            setPasswordLamaError(val.message)
                        } else if (val.path === 'passwordBaru') {
                            setPasswordBaruError(val.message)
                        } else if (val.path === 'repasswordBaru') {
                            setRepasswordBaruError(val.message)
                        }
                    })
                }
            }
        }).then((response) => {
            if (response.status === 200 && response.data?.message === 'Berhasil diubah') {
                Swal.fire({
                    title: 'Berhasil!',
                    text: 'Data password sudah berhasil diubah',
                    icon: 'success',
                }).then(() => {
                    setPasswordLama('')
                    setPasswordLamaError('')
                    setPasswordBaru('')
                    setPasswordBaruError('')
                    setRepasswordBaru('')
                    setRepasswordBaruError('')
                })
            }
        })
    }

    return (
        <>
            <div className="page-header">
                <h1 className="page-title">Pengaturan</h1>
                <div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="https://iuranmasjid.skripsita.online/admin">Admin</a>
                        </li>
                        <li className="breadcrumb-item">
                            <a href={undefined}>Pengaturan</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Akun
                        </li>
                    </ol>
                </div>
            </div>
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h3 className="card-title">Ubah Password</h3>
                </div>
                <div className="card-body">
                    <form method="POST" onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="passwordLama" className="mb-1">Password Lama <span className="text-danger">*</span></label>
                            <input type="password" className={`form-control ${passwordLamaError !== '' ? 'is-invalid' : ''}`} id="passwordLama" name="passwordLama" value={passwordLama} onChange={(e) => setPasswordLama(e.target.value)} required />
                            <div className="invalid-feedback">{passwordLamaError}</div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="passwordBaru" className="mb-1">Password Baru <span className="text-danger">*</span></label>
                            <input type="password" className={`form-control ${passwordBaruError !== '' ? 'is-invalid' : ''}`} id="passwordBaru" name="passwordBaru" value={passwordBaru} onChange={(e) => setPasswordBaru(e.target.value)} required />
                            <div className="invalid-feedback">{passwordBaruError}</div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="repasswordBaru" className="mb-1">Konfirmasi Password Baru <span className="text-danger">*</span></label>
                            <input type="password" className={`form-control ${repasswordBaruError !== '' ? 'is-invalid' : ''}`} id="repasswordBaru" name="repasswordBaru" value={repasswordBaru} onChange={(e) => setRepasswordBaru(e.target.value)} required />
                            <div className="invalid-feedback">{repasswordBaruError}</div>
                        </div>
                        <div className="clearfix">
                            <div className="float-end">
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default UbahPassword