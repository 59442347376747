import React, { useState, useEffect } from 'react'
import './mengapa.scss'
import axios from '../../api/axios'

const Mengapa = () => {
    const [mengapaKami, setMengapaKami] = useState('')

    const getMengapaKami = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=mengapa_kami').then((response) => {
            setMengapaKami(response.data.nilai)
        })
    }

    useEffect(() => {
        getMengapaKami()
    }, [])

    return (
        <section id="mengapa_kami">
            <div className="bg-danger px-3 py-4 text-white">
                <div className="container">
                    <div className="title_faq">
                        <img src={require('../../assets/icons/mengapa_kami.png')} alt="" />
                    </div>
                    <h6 className="text-center fw-500 text-light mb-5 title_desc">(Alasan Kenapa Harus Memilih Kami)</h6>
                    <p className="mb-0" dangerouslySetInnerHTML={{ __html: mengapaKami }} />
                </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path fill="#dc3545" fillOpacity="1" d="M0,128L48,117.3C96,107,192,85,288,80C384,75,480,85,576,117.3C672,149,768,203,864,202.7C960,203,1056,149,1152,144C1248,139,1344,181,1392,202.7L1440,224L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
            </svg>
        </section>
    )
}

export default Mengapa