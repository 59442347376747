import axios from "../api/axios"
import useAuth from "./useAuth"

const useRefreshToken = () => {
    const { setAuth } = useAuth()

    const refresh = async () => {
        const response = await axios.get('auth/refresh_token', {
            withCredentials: true
        })

        setAuth(prev => {
            return { ...prev, nama_lengkap: response.data.nama_lengkap, access_token: response.data.access_token, id: response.data.id, email: response.data.email, gambar: response.data.gambar }
        })
        return response.data.access_token
    }

    return refresh
}

export default useRefreshToken