import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'
import Swal from 'sweetalert2'
import useAuth from '../../../../hooks/useAuth'

const UbahUser = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const { auth } = useAuth()
    const axiosPrivate = useAxiosPrivate()
    const [namaLengkap, setNamaLengkap] = useState(null)
    const [email, setEmail] = useState(null)
    const [gambar, setGambar] = useState(null)
    const [namaLengkapError, setNamaLengkapError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [gambarError, setGambarError] = useState('')

    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                const response = await axiosPrivate.get(`user/${id}`)

                if (response.data !== null) {
                    const { nama_lengkap, email } = response.data
                    setNamaLengkap(nama_lengkap)
                    setEmail(email)
                }
            } else {
                navigate(-1)
            }
        }

        fetchData()
    }, [auth])

    const handleFile = (e) => {
        setGambar(e.target.files[0])
        setGambarError('')
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        const form = new FormData()
        form.append('nama_lengkap', namaLengkap)
        form.append('email', email)
        form.append('gambar', gambar)
        axiosPrivate.post(`user/update/${id}`, form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).catch((err) => {
            if (err.response.data?.errors) {
                if (Array.isArray(err.response.data.errors) === true) {
                    err.response.data.errors.map((val) => {
                        if (val.path === 'email') {
                            setEmailError(val.message)
                        } else if (val.path === 'nama_lengkap') {
                            setNamaLengkapError(val.message)
                        } else if (val.path === 'gambar') {
                            setGambarError(val.message)
                        }
                    })
                }
            } else {
                if (err.response.data.errors.field === 'gambar') {
                    setGambarError(err.response.data.errors.code)
                }
            }
        }).then((response) => {
            if (response.status === 200 && response.data?.message === 'Berhasil diubah') {
                Swal.fire({
                    title: 'Berhasil!',
                    text: 'Data user sudah berhasil diubah',
                    icon: 'success',
                }).then(() => {
                    navigate('/admin/user')
                })
            }
        })
    }

    return (
        <>
            <div className="page-header">
                <h1 className="page-title">User</h1>
                <div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="https://iuranmasjid.skripsita.online/admin">Admin</a>
                        </li>
                        <li className="breadcrumb-item">
                            <a href={undefined}>Data Master</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            User
                        </li>
                    </ol>
                </div>
            </div>
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h3 className="card-title">Ubah User</h3>
                </div>
                <div className="card-body">
                    <form method="POST" encType="mulipart/form-data" onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="email" className="mb-1">Email Address <span className="text-danger">*</span></label>
                            <input type="email" className={`form-control ${emailError !== '' ? 'is-invalid' : ''}`} id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                            <div className="invalid-feedback">{emailError}</div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="nama_lengkap" className="mb-1">Nama Lengkap <span className="text-danger">*</span></label>
                            <input type="text" className={`form-control ${namaLengkapError !== '' ? 'is-invalid' : ''}`} id="nama_lengkap" name="nama_lengkap" value={namaLengkap} onChange={(e) => setNamaLengkap(e.target.value)} required />
                            <div className="invalid-feedback d-block">{namaLengkapError}</div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="gambar" className="mb-1">Gambar</label>
                            <input type="file" className={`form-control ${gambarError !== '' ? 'is-invalid' : ''}`} id="gambar" name="gambar" onChange={handleFile} />
                            <div className="invalid-feedback d-block">{gambarError}</div>
                        </div>
                        <div className="clearfix">
                            <div className="float-end">
                                <button type="button" className="btn btn-secondary me-2" onClick={() => navigate(-1)}>Kembali</button>
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default UbahUser