import React, { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import axios, { BASE_URL } from '../../api/axios'
import Navbar from '../../components/navbar/Navbar'
import ImageHeader from '../../components/image_header/ImageHeader'
import SEO from '../../components/SEO'
import Chat from '../../components/chat/Chat'
import ScrollTop from '../../components/scroll_top/ScrollTop'
import Footer from '../../components/footer/Footer'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignIn, faCircle, faMinus } from '@fortawesome/free-solid-svg-icons'
import LazyLoad from 'react-lazyload'
import useAuth from '../../hooks/useAuth'

const Login = () => {
    const { auth, setAuth } = useAuth()
    const navigate = useNavigate()
    const [header, setHeader] = useState('')
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    const [password, setPassword] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const checkLogin = async () => {
        if (auth.access_token) {
            window.location.href = '/'
        }
    }
    const getHeader = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=header_login')
            .then((response) => {
                setHeader(response.data.nilai)
            })
    }
    const handleSubmit = async (e) => {
        e.preventDefault()

        await axios.post('auth/login', {
            email: email,
            password: password
        }, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        }).catch((err) => {
            if (err.response.data?.errors) {
                if (Array.isArray(err.response.data.errors) === true) {
                    err.response.data.errors.map((val) => {
                        if (val.path === 'email') {
                            setEmailError(val.message)
                        } else if (val.path === 'password') {
                            setPasswordError(val.message)
                        }
                    })
                }
            }
        }).then((response) => {
            if (response.status === 200) {
                // Set Cookie
                setAuth({ email: email, nama_lengkap: response.data.nama_lengkap, access_token: response.data.access_token, id: response.data.id, gambar: response.data.gambar })
                navigate('/')
            }
        })

    }

    useEffect(() => {
        checkLogin()
    }, [auth])

    useEffect(() => {
        getHeader()
    }, [])

    return (
        <div>
            <SEO title="Login" description="Hello World" />
            <Navbar />
            <ImageHeader image={BASE_URL + '/../img/' + header} />
            <div className="container">
                <div className="card mb-5 shadow" style={{ 'borderRadius': '2rem', 'overflow': 'hidden' }}>
                    <div className="card-header d-flex align-items-center justify-content-between">
                        <div>
                            <FontAwesomeIcon icon={faCircle} className="me-2 text-primary" />
                            <FontAwesomeIcon icon={faCircle} className="me-2 text-danger" />
                            <FontAwesomeIcon icon={faCircle} className="me-2 text-info" />
                        </div>
                        <a data-bs-toggle="collapse" href="#test-block" aria-controls="test-block">
                            <FontAwesomeIcon icon={faMinus} className="me-2 text-info" />
                        </a>
                    </div>
                    <div className="card-body cpllapse show" id="test-block">
                        <div className="row align-items-center">
                            <div className="col-md-6 d-md-block d-none text-center">
                                <LazyLoad>
                                    <img src={require('../../assets/image/2853458.jpg')} className="img-fluid w-75" alt="" />
                                </LazyLoad>
                            </div>
                            <div className="col-md-6">
                                <div className="title_faq mb-0">
                                    <img src={require('../../assets/icons/LOGIN.png')} alt="" />
                                </div>
                                <p className="text-muted mb-4" style={{ 'fontSize': '1rem' }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                <form onSubmit={handleSubmit} method="POST">
                                    <div className="mb-3">
                                        <label htmlFor="email" className="mb-1 fw-500">Email Address</label>
                                        <input type="email" className={`form-control ${emailError !== '' ? 'is-invalid' : ''}`} id="email" name="email" onChange={(e) => setEmail(e.target.value)} required />
                                        <div className="invalid-feedback">{emailError !== '' ? emailError : ''}</div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="password" className="mb-1 fw-500">Password</label>
                                        <input type="password" className={`form-control ${passwordError !== '' ? 'is-invalid' : ''}`} id="password" name="password" onChange={(e) => setPassword(e.target.value)} required />
                                        <div className="invalid-feedback">{passwordError !== '' ? passwordError : ''}</div>
                                        <span className="clearfix">
                                            <Link to="/forgot_password" className="float-end text-decoration-none">Lupa Password?</Link>
                                        </span>
                                    </div>
                                    <div className="clearfix">
                                        <button type="submit" className="btn btn-primary px-4 rounded-pill shadow float-end">
                                            <FontAwesomeIcon icon={faSignIn} />&nbsp;
                                            Login
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Chat />
            <ScrollTop />
            <Footer />
        </div>
    )
}

export default Login