import React, { useEffect, useState } from 'react'
import Navbar from '../../components/navbar/Navbar'
import ImageHeader from '../../components/image_header/ImageHeader'
import SEO from '../../components/SEO'
import Chat from '../../components/chat/Chat'
import ScrollTop from '../../components/scroll_top/ScrollTop'
import Footer from '../../components/footer/Footer'
import ImageGallery from 'react-image-gallery';
import axios, { BASE_URL } from '../../api/axios'
import './gallery.scss'

const Gallery = () => {
    const [listGallery, setlistGallery] = useState(null)
    const [header, setHeader] = useState('')
    const getGallery = async () => {
        const response = await axios.get('gallery')
        const response_data = []

        if (response.data !== null && response.data.length > 0) {
            response.data.map((val, index, arr) => {
                response_data.push({
                    original: BASE_URL + '/../img/' + val.file,
                    thumbnail: BASE_URL + '/../img/' + val.file,
                    description: val.description,
                    loading: 'lazy'
                })
            })
        }
        setlistGallery(response_data)
    }
    const getHeader = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=header_gallery')
            .then((response) => {
                setHeader(response.data.nilai)
            })
    }

    useEffect(() => {
        getGallery()
        getHeader()
    }, [])

    return (
        <div>
            <SEO title="Gallery" description="Hello World" />
            <Navbar />
            <ImageHeader image={BASE_URL + '/../img/' + header} />
            <div className="container">
                <div className="title_faq mb-4">
                    <img src={require('../../assets/icons/Gallery.png')} alt="" />
                </div>
                <div className="mb-4">
                    {listGallery !== null && listGallery.length > 0 ? (
                        <ImageGallery items={listGallery} thumbnailPosition="bottom" showBullets="true" />
                    ) : (<></>)}
                </div>
            </div>
            <Chat />
            <ScrollTop />
            <Footer />
        </div>
    )
}

export default Gallery