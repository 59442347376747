import React, { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'
import Swal from 'sweetalert2'
import { Editor } from '@tinymce/tinymce-react';

const TambahFAQ = () => {
    const navigate = useNavigate()
    const axiosPrivate = useAxiosPrivate()
    const jawabanRef = useRef(null);
    const [pertanyaan, setPertanyaan] = useState(null)
    const [prioritas, setPrioritas] = useState(null)
    const [pertanyaanError, setPertanyaanError] = useState('')
    const [jawabanError, setJawabanError] = useState('')
    const [prioritasError, setPrioritasError] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault()
        axiosPrivate.post('FAQ/create', {
            pertanyaan: pertanyaan,
            jawaban: jawabanRef.current.getContent(),
            prioritas: prioritas,
        }).catch((err) => {
            if (err.response.data?.errors) {
                if (Array.isArray(err.response.data.errors) === true) {
                    err.response.data.errors.map((val) => {
                        if (val.path === 'prioritas') {
                            setPrioritasError(val.message)
                        } else if (val.path === 'pertanyaan') {
                            setPertanyaanError(val.message)
                        } else if (val.path === 'jawaban') {
                            setJawabanError(val.message)
                        }
                    })
                }
            }
        }).then((response) => {
            if (response.status === 200 && response.data?.message === 'Berhasil ditambahkan') {
                Swal.fire({
                    title: 'Berhasil!',
                    text: 'Data FAQ sudah berhasil ditambahkan',
                    icon: 'success',
                }).then(() => {
                    navigate('/admin/faq')
                })
            }
        })
    }

    return (
        <>
            <div className="page-header">
                <h1 className="page-title">FAQ</h1>
                <div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="https://iuranmasjid.skripsita.online/admin">Admin</a>
                        </li>
                        <li className="breadcrumb-item">
                            <a href={undefined}>Data Master</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            FAQ
                        </li>
                    </ol>
                </div>
            </div>
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h3 className="card-title">Tambah FAQ</h3>
                </div>
                <div className="card-body">
                    <form method="POST" onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="pertanyaan" className="mb-1">Pertanyaan <span className="text-danger">*</span></label>
                            <input type="text" className={`form-control ${pertanyaanError !== '' ? 'is-invalid' : ''}`} id="pertanyaan" name="pertanyaan" value={pertanyaan} onChange={(e) => setPertanyaan(e.target.value)} required />
                            <div className="invalid-feedback">{pertanyaanError}</div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="jawaban" className="mb-1">Jawaban <span className="text-danger">*</span></label>
                            <Editor
                                apiKey="pua31uis8r281btkfuexdw31h7bdoh1orf6pz6oj82fyf9r8"
                                onInit={(evt, editor) => jawabanRef.current = editor}
                                initialValue="<p>This is the initial content of the editor.</p>"
                                init={{
                                    height: 500,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar: 'undo redo | formatselect | ' +
                                        'bold italic backcolor | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                        'removeformat | help',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }}
                            />
                            <div className="invalid-feedback d-block">{jawabanError}</div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="prioritas" className="mb-1">Prioritas <span className="text-danger">*</span></label>
                            <input type="number" min={1} className={`form-control ${prioritasError !== '' ? 'is-invalid' : ''}`} id="prioritas" name="prioritas" value={prioritas} onChange={(e) => setPrioritas(e.target.value)} required />
                            <div className="invalid-feedback">{prioritasError}</div>
                        </div>
                        <div className="clearfix">
                            <div className="float-end">
                                <button type="button" className="btn btn-secondary me-2" onClick={() => navigate(-1)}>Kembali</button>
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default TambahFAQ