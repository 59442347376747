import React from 'react'

const FAQList = ({ id, pertanyaan, jawaban, start = 0 }) => {
    return (
        <div className="accordion mb-3" id={`faq_${id}`}>
            <div className="accordion-item">
                <h2 className="accordion-header text-dark" id={`heading${id}`}>
                    <button className={`accordion-button text-dark fw-500 ${start === 1 ? '' : 'collapsed'}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${id}`} aria-expanded="true" aria-controls={`collapse${id}`}>
                        {pertanyaan}
                    </button>
                </h2>
                <div id={`collapse${id}`} className={`accordion-collapse collapse ${start === 1 ? 'show' : ''}`} aria-labelledby={`heading${id}`} data-bs-parent={`faq_${id}`}>
                    <div className="accordion-body" dangerouslySetInnerHTML={{ __html: jawaban }} />
                </div>
            </div>
        </div>
    )
}

export default FAQList