import React, { useEffect, useState } from 'react'
import Navbar from '../../components/navbar/Navbar'
import ImageHeader from '../../components/image_header/ImageHeader'
import SEO from '../../components/SEO'
import Chat from '../../components/chat/Chat'
import ScrollTop from '../../components/scroll_top/ScrollTop'
import Footer from '../../components/footer/Footer'
import TeamList from './TeamList'
import './struktur_organisasi.scss'
import axios, { BASE_URL } from '../../api/axios'

const StrukturOrganisasi = () => {
    const [listKaryawan, setListKaryawan] = useState(null)
    const [header, setHeader] = useState('')
    const getKaryawan = async () => {
        await axios.get('struktur_organisasi').then((response) => {
            setListKaryawan(response.data)
        })
    }
    const getHeader = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=header_struktur_organisasi')
            .then((response) => {
                setHeader(response.data.nilai)
            })
    }

    useEffect(() => {
        getKaryawan()
        getHeader()
    }, [])

    return (
        <div style={{ 'background-color': '#eee' }} id="struktur_organisasi">
            <SEO title="Struktur Organisasi" description="Hello World" />
            <Navbar />
            <ImageHeader image={BASE_URL + '/../img/' + header} />
            <div className="container">
                <div className="title_faq mb-4">
                    <img src={require('../../assets/icons/STRUKTUR ORGANISASI.png')} alt="" />
                </div>
                <div className="row mb-4">
                    {listKaryawan !== null && listKaryawan.length > 0 ? listKaryawan.map((val, key, arr) => (
                        <TeamList nama={val.nama_lengkap} gambar={`${BASE_URL} /../struktur_organisasi/${val.file}`} jabatan={val.jabatan} instagram={val.instagram !== null && val.instagram !== '' ? val.instagram : 'null'} facebook={val.facebook !== null && val.facebook !== '' ? val.facebook : 'null'} twitter={val.twitter !== null && val.twitter !== '' ? val.twitter : 'null'} linkedin={val.linkedin !== null && val.linkedin !== '' ? val.linkedin : 'null'} />
                    )) : (
                        <></>
                    )}
                </div>
            </div>
            <Chat />
            <ScrollTop />
            <Footer />
        </div>
    )
}

export default StrukturOrganisasi