import React, { useEffect, useState, useContext } from 'react'
import axios, { BASE_URL } from '../../api/axios'
import Navbar from '../../components/navbar/Navbar'
import ImageHeader from '../../components/image_header/ImageHeader'
import SEO from '../../components/SEO'
import Chat from '../../components/chat/Chat'
import ScrollTop from '../../components/scroll_top/ScrollTop'
import Footer from '../../components/footer/Footer'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignIn, faCircle, faMinus } from '@fortawesome/free-solid-svg-icons'
import LazyLoad from 'react-lazyload'

const ForgotPassword = () => {
    const [header, setHeader] = useState('')
    const getHeader = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=header_login')
            .then((response) => {
                setHeader(response.data.nilai)
            })
    }
    return (
        <div>
            <SEO title="Forgot Password" description="Hello World" />
            <Navbar />
            <ImageHeader image={BASE_URL + '/../img/' + header} />
            <div className="container">
                <div className="card mb-5 shadow" style={{ 'borderRadius': '2rem', 'overflow': 'hidden' }}>
                    <div className="card-header d-flex align-items-center justify-content-between">
                        <div>
                            <FontAwesomeIcon icon={faCircle} className="me-2 text-primary" />
                            <FontAwesomeIcon icon={faCircle} className="me-2 text-danger" />
                            <FontAwesomeIcon icon={faCircle} className="me-2 text-info" />
                        </div>
                        <a data-bs-toggle="collapse" href="#test-block" aria-controls="test-block">
                            <FontAwesomeIcon icon={faMinus} className="me-2 text-info" />
                        </a>
                    </div>
                    <div className="card-body cpllapse show" id="test-block">
                        <div className="row align-items-center">
                            <div className="col-md-6 d-md-block d-none text-center">
                                <LazyLoad>
                                    <img src={require('../../assets/image/2853458.jpg')} className="img-fluid w-75" alt="" />
                                </LazyLoad>
                            </div>
                            <div className="col-md-6">
                                <div className="title_faq mb-0">
                                    <img src={require('../../assets/icons/FORGOT PASSWORD.png')} alt="" />
                                </div>
                                <p className="text-muted mb-4" style={{ 'fontSize': '1rem' }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                <div className="mb-3">
                                    <label htmlFor="email" className="mb-1 fw-500">Email Address</label>
                                    <input type="email" className="form-control" id="email" name="email" required />
                                </div>
                                <div className="clearfix mb-3">
                                    <button type="submit" className="btn btn-primary px-4 rounded-pill shadow float-end">
                                        <FontAwesomeIcon icon={faSignIn} />&nbsp;
                                        Reset
                                    </button>
                                </div>
                                <div className="text-center">Ingat password akun Anda? <Link to="/login">Login</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Chat />
            <ScrollTop />
            <Footer />
        </div>
    )
}

export default ForgotPassword