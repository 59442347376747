import React, { useState, useEffect } from 'react'
import Navbar from '../../components/navbar/Navbar'
import SEO from '../../components/SEO'
import Chat from '../../components/chat/Chat'
import ScrollTop from '../../components/scroll_top/ScrollTop'
import Footer from '../../components/footer/Footer'
import LazyLoad from 'react-lazyload'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons'
import './berita.scss'
import axios, { BASE_URL } from '../../api/axios'
// import Skeleton from 'react-loading-skeleton'

const BeritaInfo = () => {
    const { id } = useParams()
    const [title, setTitle] = useState('')
    const [thumbnail, setThumbnail] = useState('')
    const [text, setText] = useState('')
    const [tanggal, setTanggal] = useState('')
    const [jam, setJam] = useState('')
    useEffect(() => {
        getBerita()
    }, [])

    const getBerita = async () => {
        const response = await axios.get(`berita/${id}`)

        if (response.data !== null) {
            let myDate = new Date(response.data.createdAt);
            var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            let tanggal = myDate.toLocaleDateString("id-ID", options)
            let jam = myDate.toLocaleTimeString("id-ID")

            setTitle(response.data.title)
            setThumbnail(response.data.file)
            setText(response.data.text)
            setTanggal(tanggal)
            setJam(jam)
        }
    }

    return (
        <div id="berita_info">
            <SEO title={title} description="Hello World" />
            <Navbar />
            <div className="container mt-5 mb-5 pt-5">
                <div className="row">
                    <div className="col-md-12 mx-auto">
                        <h4 className="text-center fw-bold mt-4 px-4">
                            {title}
                        </h4>
                        <small className="text-muted d-block text-center">{tanggal}</small>
                        <small className="text-muted d-block text-center mb-3">{jam.substring(0, 5) + ' WIB'}</small>
                        <div className="clearfix mb-2 bagikan">
                            <div className="float-end d-flex align-items-center">
                                <small className="fw-500 text-muted">BAGIKAN</small>
                                <a href={`https://web.facebook.com/sharer/sharer.php?u=https://pasifikjayalogistik.com/berita/${id}`} className="text-decoration-none ms-3" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebook} /></a>
                                <a href={`https://twitter.com/intent/tweet?text=${title} https://pasifikjayalogistik.com/berita/${id}`} className="text-decoration-none ms-3" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faTwitter} /></a>
                            </div>
                        </div>
                        <LazyLoad>
                            <img className="img-thumbnails mb-4" src={BASE_URL + '/../berita/' + thumbnail} alt="" />
                        </LazyLoad>
                        <p dangerouslySetInnerHTML={{ __html: text }} />
                    </div>
                    {/* <div className="col-md-5 px-md-5 pt-md-5 mt-md-5 mt-4">
                        <h5 className="text-primary fw-bold mt-md-5 pt-md-2">Berita Terpopuler</h5>
                        <div className="d-flex align-items-center">
                            <span className="fs-4 fw-500 me-3 text-muted" style={{ 'minWidth': '32px' }}>#1</span>
                            <p className="mb-0 fw-bold">Guardiola Kalem dari Kejaran Liverpool</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <span className="fs-4 fw-500 me-3 text-muted" style={{ 'minWidth': '32px' }}>#2</span>
                            <p className="mb-0 fw-bold">Guardiola Kalem dari Kejaran Liverpool</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <span className="fs-4 fw-500 me-3 text-muted" style={{ 'minWidth': '32px' }}>#3</span>
                            <p className="mb-0 fw-bold">Guardiola Kalem dari Kejaran Liverpool</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <span className="fs-4 fw-500 me-3 text-muted" style={{ 'minWidth': '32px' }}>#4</span>
                            <p className="mb-0 fw-bold">Guardiola Kalem dari Kejaran Liverpool</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <span className="fs-4 fw-500 me-3 text-muted" style={{ 'minWidth': '32px' }}>#5</span>
                            <p className="mb-0 fw-bold">Guardiola Kalem dari Kejaran Liverpool</p>
                        </div>
                    </div> */}
                </div>
            </div>
            <Chat />
            <ScrollTop />
            <Footer />
        </div>
    )
}

export default BeritaInfo