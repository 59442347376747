import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import './struktur_organisasi.scss'

const TeamList = ({ gambar, nama, jabatan, instagram = null, facebook = null, twitter = null, linkedin = null }) => {
    return (
        <div className="col-md-4 mx-auto pegawai">
            <div className="px-3 py-2 text-center">
                <div className="text-center mb-3">
                    <img src={gambar} className="foto_pegawai shadow" alt="" />
                </div>
                <h5 className="text-center fw-bold mb-1">{nama}</h5>
                <p className="text-center fw-500 text-muted mb-1">{jabatan}</p>
                {facebook !== 'null' && <a href={`https://www.facebook.com/${facebook}`} className="text-decoration-none mx-1" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebook} /></a>}
                {twitter !== 'null' && <a href={`https://www.twitter.com/${twitter}`} className="text-decoration-none mx-1" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faTwitter} /></a>}
                {instagram !== 'null' && <a href={`https://www.instagram.com/${instagram}`} className="text-decoration-none mx-1" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} /></a>}
                {linkedin !== 'null' && <a href={`https://www.linkedin.com/${linkedin}`} className="text-decoration-none mx-1" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a>}
            </div>
        </div>
    )
}

export default TeamList