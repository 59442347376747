import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'
import useAuth from '../../../../hooks/useAuth'
import Swal from 'sweetalert2'
import { Editor } from '@tinymce/tinymce-react';

const UbahBerita = () => {
    const navigate = useNavigate()
    const axiosPrivate = useAxiosPrivate()
    const { auth } = useAuth()
    const { id } = useParams()
    const textRef = useRef(null);
    const [file, setFile] = useState(null)
    const [text, setText] = useState(null)
    const [title, setTitle] = useState(null)
    const [fileError, setFileError] = useState('')
    const [textError, setTextError] = useState('')
    const [titleError, setTitleError] = useState('')

    const handleFile = (e) => {
        setFile(e.target.files[0])
        setFileError('')
    }

    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                const response = await axiosPrivate.get(`berita/${id}`)

                if (response.data !== null) {
                    const { title, text } = response.data
                    setText(text)
                    setTitle(title)
                }
            } else {
                navigate(-1)
            }
        }

        fetchData()
    }, [auth])

    const handleSubmit = (e) => {
        e.preventDefault()
        const form = new FormData()
        form.append('file', file)
        form.append('title', title)
        form.append('text', textRef.current.getContent().replace(/"/g, '&quot;'))
        console.log(textRef.current.getContent().replace(/"/g, '&quot;'))
        axiosPrivate.post(`berita/update/${id}`, form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).catch((err) => {
            if (err.response.data?.errors) {
                if (Array.isArray(err.response.data.errors) === true) {
                    err.response.data.errors.map((val) => {
                        if (val.path === 'file') {
                            setFileError(val.message)
                        } else if (val.path === 'text') {
                            setTextError(val.message)
                        } else if (val.path === 'title') {
                            setTitleError(val.message)
                        }
                    })
                }
            }
        }).then((response) => {
            if (response.status === 200 && response.data?.message === 'Berhasil diubah') {
                Swal.fire({
                    title: 'Berhasil!',
                    text: 'Data berita sudah berhasil diubah',
                    icon: 'success',
                }).then(() => {
                    navigate('/admin/berita')
                })
            }
        })
    }

    return (
        <>
            <div className="page-header">
                <h1 className="page-title">Berita</h1>
                <div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="https://iuranmasjid.skripsita.online/admin">Admin</a>
                        </li>
                        <li className="breadcrumb-item">
                            <a href={undefined}>Data Master</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Berita
                        </li>
                    </ol>
                </div>
            </div>
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h3 className="card-title">Ubah Berita</h3>
                </div>
                <div className="card-body">
                    <form method="POST" encType="mulipart/form-data" onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="file" className="mb-1">Thumbnail</label>
                            <input type="file" className={`form-control ${fileError !== '' ? 'is-invalid' : ''}`} id="file" name="file" onChange={handleFile} />
                            <div className="invalid-feedback">{fileError}</div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="title" className="mb-1">Title <span className="text-danger">*</span></label>
                            <input type="text" className={`form-control ${titleError !== '' ? 'is-invalid' : ''}`} id="title" name="title" value={title} onChange={(e) => setTitle(e.target.value)} required />
                            <div className="invalid-feedback">{textError}</div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="text" className="mb-1">Text <span className="text-danger">*</span></label>
                            <Editor
                                apiKey="pua31uis8r281btkfuexdw31h7bdoh1orf6pz6oj82fyf9r8"
                                onInit={(evt, editor) => textRef.current = editor}
                                initialValue={text}
                                init={{
                                    height: 500,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar: 'undo redo | formatselect | ' +
                                        'bold italic backcolor | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                        'removeformat | help',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                    entity_encoding: 'named'
                                    // cleanup: true
                                }}
                            />
                            <div className="invalid-feedback">{titleError}</div>
                        </div>
                        <div className="clearfix">
                            <div className="float-end">
                                <button type="button" className="btn btn-secondary me-2" onClick={() => navigate(-1)}>Kembali</button>
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default UbahBerita