import React, { useState, useEffect } from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import useAplikasiKonfigurasi from '../../hooks/useAplikasiKonfigurasi'
import useKonfigurasi from '../../hooks/useKonfigurasi'

const PersistAplikasi = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [isRefreshed, setIsRefreshed] = useState(false)
    const konfigurasi = useAplikasiKonfigurasi()
    const { aplikasi } = useKonfigurasi()
    const nagivate = useNavigate()
    const location = useLocation().pathname

    useEffect(() => {
        if (isRefreshed === false) {
            const verifyAplikasi = async () => {
                try {
                    setIsRefreshed(true)
                    await konfigurasi()
                } catch (err) {
                    console.error(err)
                } finally {
                    setIsLoading(false)
                }

            }

            !aplikasi?.namaAplikasi ? verifyAplikasi() : setIsLoading(false)
        }
    }, [])

    return (
        <>
        </>
    )
}

export default PersistAplikasi