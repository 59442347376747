import React, { useEffect, useState } from 'react'
import Navbar from '../../components/navbar/Navbar'
import ImageHeader from '../../components/image_header/ImageHeader'
import SEO from '../../components/SEO'
import Chat from '../../components/chat/Chat'
import ScrollTop from '../../components/scroll_top/ScrollTop'
import Footer from '../../components/footer/Footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faMinus } from '@fortawesome/free-solid-svg-icons'
import axios, { BASE_URL } from '../../api/axios'

const Sejarah = () => {
    const [sejarah, setSejarah] = useState('')
    const [header, setHeader] = useState('')
    const getSejarah = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=sejarah').then((response) => {
            setSejarah(response.data.nilai)
        })
    }
    const getHeader = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=header_sejarah')
            .then((response) => {
                setHeader(response.data.nilai)
            })
    }

    useEffect(() => {
        getSejarah()
        getHeader()
    }, [])
    return (
        <div style={{ 'background-color': '#eee' }}>
            <SEO title="Sejarah" description="Hello World" />
            <Navbar />
            <ImageHeader image={BASE_URL + '/../img/' + header} />
            <div className="container">
                <div className="title_faq mb-4">
                    <img src={require('../../assets/icons/SEJARAH.png')} alt="" />
                </div>
                <div className="card mb-5 shadow" style={{ 'borderRadius': '2rem', 'overflow': 'hidden' }}>
                    <div className="card-header d-flex align-items-center justify-content-between">
                        <div>
                            <FontAwesomeIcon icon={faCircle} className="me-2 text-primary" />
                            <FontAwesomeIcon icon={faCircle} className="me-2 text-danger" />
                            <FontAwesomeIcon icon={faCircle} className="me-2 text-info" />
                        </div>
                        <a data-bs-toggle="collapse" href="#test-block" aria-controls="test-block">
                            <FontAwesomeIcon icon={faMinus} className="me-2 text-info" />
                        </a>
                    </div>
                    <div className="card-body collapse show" id='test-block' dangerouslySetInnerHTML={{ __html: sejarah }} />
                </div>
            </div>
            <Chat />
            <ScrollTop />
            <Footer />
        </div>
    )
}

export default Sejarah