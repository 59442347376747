import axios from "axios"

// export const BASE_URL = 'https://backend.starkom.my.id:8084/api'
// export const BASE_URL2 = 'https://backend.starkom.my.id:8084'
// export const BASE_URL = 'https://backendchatbot.hauscitaindonesia.com/api'
// export const BASE_URL2 = 'https://backendchatbot.hauscitaindonesia.com:8081'
export const BASE_URL = 'https://dendirenaldo.com/backend/api'
export const BASE_URL2 = 'https://dendirenaldo.com/backend'

export default axios.create({
    baseURL: BASE_URL
})

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true
})