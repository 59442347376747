import React, { useState, useEffect } from 'react'
import { faCheck, faEdit, faEllipsisV, faInfoCircle, faPlus, faTimes, faTrash, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useNavigate } from 'react-router-dom'
import axios, { BASE_URL, BASE_URL2 } from '../../../../api/axios'
import Swal from 'sweetalert2'
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'
import useAuth from '../../../../hooks/useAuth'
import SEO from '../../../../components/SEO'
import { io } from 'socket.io-client';

const Chat = () => {
    let nomor = 1
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const axiosPrivate = useAxiosPrivate()
    const { auth } = useAuth()
    const nagivate = useNavigate()
    const [socketAPI, setSocketAPI] = useState(null)
    const [listChat, setListChat] = useState(null)

    const tanggalFormat = (tanggal) => {
        let currentTanggal = new Date(tanggal)
        return currentTanggal.toLocaleDateString("id-ID", options) + ', ' + currentTanggal.toLocaleTimeString("id-ID").substring(0, 5) + ' WIB'
    }

    const ambilAlih = async (id) => {
        await axiosPrivate.post(`chat/ambil_alih/${id}`, {
            replied_by: auth?.id
        }).then((res) => {
            if (res !== null) {
                Swal.fire({
                    title: 'Berhasil!',
                    text: 'Data chat sudah berhasil diambil alih',
                    icon: 'success',
                }).then(() => {
                    if (socketAPI !== null) {
                        socketAPI.emit('ambil_alih', { cookie_id: res.data.data.cookie_id })
                    }

                    getChat()
                })
            }
        })
    }

    const getChat = async () => {
        const response = await axiosPrivate.post('chat')

        if (response?.data && response?.data?.length > 0) {
            setListChat(response.data)
        }
    }

    const hapus = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const response = await axiosPrivate.delete(`chat/delete/${id}`)
                if (response.status === 200 && response.data?.message == 'Berhasil dihapus') {
                    Swal.fire(
                        'Berhasil!',
                        'Data chat sudah berhasil dihapuskan.',
                        'success'
                    )
                    getChat()
                }
            }
        })
    }

    useEffect(() => {
        const socket_data = io.connect(BASE_URL2)
        setSocketAPI(socket_data)
    }, [])

    useEffect(() => {
        getChat()
    }, [auth])
    return (
        <>
            <SEO title="Chat" description="Hello World" />
            <div className="page-header">
                <h1 className="page-title">Daftar Chat</h1>
                <div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="https://iuranmasjid.skripsita.online/admin">Admin</a>
                        </li>
                        <li className="breadcrumb-item">
                            <a href={undefined}>Data Master</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Chat
                        </li>
                    </ol>
                </div>
            </div>
            <div className="text-wrap mb-4">
                <div className="">
                    <div className="alert alert-info">
                        <div className="d-flex align-items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40" viewBox="0 0 24 24">
                                <path fill="#70a9ee" d="M20.05713,22H3.94287A3.02288,3.02288,0,0,1,1.3252,17.46631L9.38232,3.51123a3.02272,3.02272,0,0,1,5.23536,0L22.6748,17.46631A3.02288,3.02288,0,0,1,20.05713,22Z"></path>
                                <circle cx="12" cy="17" r="1" fill="#1170e4"></circle>
                                <path fill="#1170e4" d="M12,14a1,1,0,0,1-1-1V9a1,1,0,0,1,2,0v4A1,1,0,0,1,12,14Z"></path>
                            </svg>
                            <strong className="ms-2 fw-bolder">Informasi</strong>
                        </div>
                        <hr className="message-inner-separator" />
                        <p> Ini adalah data master. Harap pergunakan data ini dengan baik dan jika sudah tidak diperlukan
                            diperbolehkan untuk dihapus. Namun jika masih diperlukan, maka jangan dihapus karena akan mengakibatkan
                            data lainnya ikut terhapus.</p>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h3 className="card-title">Daftar Chat</h3>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead className="text-center">
                                <tr>
                                    <th className="px-3 align-middle" style={{ 'width': '5px' }}>No</th>
                                    <th className="align-middle">Email Address</th>
                                    <th className="align-middle">Nama Lengkap</th>
                                    <th className="align-middle">Tanggal</th>
                                    <th className="align-middle">Admin Respon</th>
                                    <th className="align-middle">Dibutuhkan</th>
                                    <th className="align-middle">Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listChat !== null && listChat.length > 0 ? listChat.map((val, key, arr) =>
                                (
                                    <tr key={val.id}>
                                        <td className="text-center">{nomor++}</td>
                                        <td>{listChat[key].email !== null ? listChat[key].email : 'Tidak Diketahui'}</td>
                                        <td>{listChat[key].nama_lengkap !== null ? listChat[key].nama_lengkap : 'Tidak Diketahui'}</td>
                                        <td>{tanggalFormat(listChat[key].createdAt)}</td>
                                        <td>{listChat[key].user !== null ? listChat[key].user.nama_lengkap : 'Bot'}</td>
                                        <td className="text-center">{listChat[key].is_needed === true ? (
                                            <FontAwesomeIcon icon={faCheck} className="text-primary" style={{ width: '14px', fontSize: '14px' }} />
                                        ) : (
                                            <FontAwesomeIcon icon={faTimes} className="text-danger" style={{ width: '14px', fontSize: '14px' }} />
                                        )}</td>
                                        <td className="text-center">
                                            <a href={undefined} className="cursor-pointer p-2" id="dropdown_aksi2" data-bs-toggle="dropdown" aria-expanded="false">
                                                <FontAwesomeIcon icon={faEllipsisV} className="text-secondary" aria-hidden="true" />
                                            </a>
                                            <ul className="shadow dropdown-menu px-2 py-3 ms-sm-n4 ms-n5" aria-labelledby="dropdown_aksi2">
                                                {listChat[key].replied_by !== auth?.id ? (
                                                    <li>
                                                        <button type="button" className="dropdown-item border-radius-md" onClick={() => ambilAlih(listChat[key].id)}>
                                                            <FontAwesomeIcon icon={faUserPlus} className="text-warning" style={{ width: '14px', fontSize: '14px' }} />&nbsp; Ambil Alih
                                                        </button>
                                                    </li>
                                                ) : (
                                                    <li>
                                                        <button type="button" className="dropdown-item border-radius-md" onClick={() => nagivate(`/admin/info_chat/${val.id}`)}>
                                                            <FontAwesomeIcon icon={faInfoCircle} className="text-primary" style={{ width: '14px', fontSize: '14px' }} />&nbsp; Informasi Chat
                                                        </button>
                                                    </li>
                                                )}
                                                <li>
                                                    <button type="button" className="dropdown-item border-radius-md" onClick={() => hapus(val.id)}>
                                                        <FontAwesomeIcon icon={faTrash} className="text-danger" style={{ width: '14px', fontSize: '14px' }} />&nbsp; Hapus
                                                    </button>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td colSpan={7} className="text-center">No data available in table</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Chat