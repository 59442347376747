import React, { useState, useEffect } from 'react'
import './footer.scss'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter, faInstagram, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons'
import useKonfigurasi from '../../hooks/useKonfigurasi';

const Footer = () => {
    const { aplikasi } = useKonfigurasi()
    const [namaAplikasi, setNamaAplikasi] = useState('')
    useEffect(() => {
        if (aplikasi?.namaAplikasi) {
            setNamaAplikasi(aplikasi.namaAplikasi)
        }
    }, [aplikasi])
    return (
        <footer>
            <div className="row">
                <div className="col-md-3 mx-auto">
                    <div className="row">
                        <div className="col-12 order-md-1 order-2">
                            <h6 className="text-uppercase mb-1 fw-bolder">Halaman Utama</h6>
                            <Link to="/" className="text-decoration-none d-block text-light">Dashboard</Link>
                            <Link to="/#mengapa" className="text-decoration-none d-block text-light">Mengapa Kami</Link>
                            <Link to="/#our_solution" className="text-decoration-none d-block text-light">Our Services</Link>
                            <Link to="/#partner" className="text-decoration-none d-block text-light">Partner</Link>
                            <Link to="/#faq" className="text-decoration-none d-block text-light mb-4">FAQ</Link>
                        </div>
                        <div className="col-12 order-md-2 order-1 mb-4">
                            <h6 className="text-uppercase mb-1 fw-bolder">Follow Us</h6>
                            <a href="https://www.facebook.com" className="text-decoration-none text-light me-3" target="_blank" rel="noreferrer"><FontAwesomeIcon className="fa-2x" icon={faFacebook} /></a>
                            <a href="https://www.twitter.com" className="text-decoration-none text-light me-3" target="_blank" rel="noreferrer"><FontAwesomeIcon className="fa-2x" icon={faTwitter} /></a>
                            <a href="https://www.instragram.com" className="text-decoration-none text-light me-3" target="_blank" rel="noreferrer"><FontAwesomeIcon className="fa-2x" icon={faInstagram} /></a>
                            <a href="https://www.linkedin.com" className="text-decoration-none text-light me-3" target="_blank" rel="noreferrer"><FontAwesomeIcon className="fa-2x" icon={faLinkedin} /></a>
                            <a href="https://www.youtube.com" className="text-decoration-none text-light" target="_blank" rel="noreferrer"><FontAwesomeIcon className="fa-2x" icon={faYoutube} /></a>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 mx-auto mb-4">
                    <h6 className="text-uppercase mb-1 fw-bolder">Profil Perusahan</h6>
                    <Link to="/sejarah" className="text-decoration-none d-block text-light">Sejarah</Link>
                    <Link to="/visi_misi_motto" className="text-decoration-none d-block text-light">Visi, Misi, dan Motto</Link>
                    <Link to="/struktur_organisasi" className="text-decoration-none d-block text-light">Struktur Organisasi</Link>
                </div>
                <div className="col-md-3 mx-auto mb-4">
                    <h6 className="text-uppercase mb-1 fw-bolder">Publikasi</h6>
                    <Link to="/berita" className="text-decoration-none d-block text-light">Berita</Link>
                    <Link to="/gallery" className="text-decoration-none d-block text-light">Gallery</Link>
                </div>
                <div className="col-md-3 mx-auto">
                    <h6 className="text-uppercase mb-1 fw-bolder">Tentang Kami</h6>
                    <Link to="/terms_conditions" className="text-decoration-none d-block text-light">Terms & Conditions</Link>
                    <Link to="/privacy_policy" className="text-decoration-none d-block text-light">Privacy Policy</Link>
                    <Link to="/contact" className="text-decoration-none d-block text-light">Contact</Link>
                </div>
            </div>
            <hr />
            <div className="fw-500">
                {`Copyright © ${new Date().getFullYear()}. ${namaAplikasi}. All rights reserved.`}
            </div>
        </footer>
    )
}

export default Footer