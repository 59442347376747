import React from 'react'
import LazyLoad from 'react-lazyload'
import './berita.scss'
import { Link } from "react-router-dom";
import { BASE_URL } from '../../api/axios'

const BeritaList = ({ id, title, thumbnail, tanggal, jam }) => {
    return (
        <Link className="row berita_list align-items-center text-decoration-none mb-4" to={`/berita/${id}`}>
            <div className="col-3 text-center">
                <LazyLoad>
                    <img src={BASE_URL + '/../berita/' + thumbnail} alt="" />
                </LazyLoad>
            </div>
            <div className="col-9">
                <h6 className="fw-bold text-dark">{title}</h6>
                <p className="text-muted mb-2">{tanggal}</p>
                <p className="text-muted mb-0">{jam}</p>
            </div>
        </Link>

    )
}

export default BeritaList