import { useState, useEffect, useContext } from 'react'
import LazyLoad from "react-lazyload"
import './navbar.scss'
import { Link, useLocation } from "react-router-dom"
import AuthContext from '../../context/AuthProvider'
import AplikasiContext from '../../context/AplikasiProvider'
import { BASE_URL } from '../../api/axios'

const Navbar = () => {
    const location = useLocation().pathname
    const [isScrolled, setIsScrolled] = useState(false)
    const [isLogin, setIsLogin] = useState(false)
    const { auth } = useContext(AuthContext)
    const { aplikasi } = useContext(AplikasiContext)
    const [namaAplikasi, setNamaAplikasi] = useState('')
    const [logoAplikasi, setLogoAplikasi] = useState('')

    useEffect(() => {
        document.querySelector('.navbar').addEventListener('shown.bs.collapse', () => {
            let element = document.querySelector('#navbarSupportedContent')

            if (isScrolled === false) {
                if (element.classList.contains('show') === true) {
                    setIsScrolled(true)
                } else {
                    setIsScrolled(false)
                }
            }
        })

        document.querySelector('.navbar').addEventListener('hidden.bs.collapse', () => {
            let element = document.querySelector('#navbarSupportedContent')

            if (isScrolled === false) {
                if (element.classList.contains('show') === true) {
                    setIsScrolled(true)
                } else {
                    if (window.pageYOffset === 0) {
                        setIsScrolled(false)
                    } else {
                        setIsScrolled(true)
                    }
                }
            }
        })
    }, [])

    useEffect(() => {
        if (aplikasi?.namaAplikasi) {
            setNamaAplikasi(aplikasi?.namaAplikasi)
        }

        if (aplikasi?.logoAplikasi) {
            setLogoAplikasi(aplikasi?.logoAplikasi)
        }
    }, [aplikasi])

    useEffect(() => {
        window.onscroll = () => {
            setIsScrolled(window.pageYOffset === 0 ? false : true)
            return () => window.onscroll = null
        }

        if (document.querySelector('#navbarSupportedContent').classList.contains('show') === false) {
            if (isScrolled === true) {
                document.querySelector('.navbar').classList.add('scrolled')
                document.querySelector('.navbar').classList.add('shadow')
                document.querySelector('.scroll-top').classList.add('scrolled')
            } else if (isScrolled === false) {
                document.querySelector('.navbar').classList.remove('scrolled')
                document.querySelector('.navbar').classList.remove('shadow')
                document.querySelector('.scroll-top').classList.remove('scrolled')
            }
        } else {
            setIsScrolled(true)
            document.querySelector('.navbar').classList.add('scrolled')
            document.querySelector('.navbar').classList.add('shadow')
            document.querySelector('.scroll-top').classList.add('scrolled')
        }
    }, [isScrolled])

    return (
        <nav className="navbar navbar-expand-lg navbar-dark fixed-top">
            <Link className="navbar-brand" to="/">
                <LazyLoad className="d-flex align-items-center">
                    <img className="img-fluid logo_navbar me-3" src={BASE_URL + '/../img/' + logoAplikasi} alt="Logo" />
                    <h6 className="d-inline-block mb-0 fw-bold">{namaAplikasi}</h6>
                </LazyLoad>
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li className="nav-item me-3">
                        <Link className={`nav-link ${location === '/' ? 'active' : ''}`} aria-current="page" to="/">Home</Link>
                    </li>
                    <li className="nav-item dropdown me-3">
                        <a className={`nav-link dropdown-toggle ${location === '/sejarah' || location === '/visi_misi_motto' || location === '/struktur_organisasi' ? 'active' : ''}`} href="/#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Profil Perusahaan
                        </a>
                        <ul className="dropdown-menu rounded-0 py-0 shadow border-0" aria-labelledby="navbarDropdown">
                            <li><Link className={`dropdown-item px-3 py-2 ${location === '/sejarah' ? 'active' : ''}`} to="/sejarah">Sejarah</Link></li>
                            <li><Link className={`dropdown-item px-3 py-2 ${location === '/visi_misi_motto' ? 'active' : ''}`} to="/visi_misi_motto">Visi, Misi, dan Motto</Link></li>
                            <li><Link className={`dropdown-item px-3 py-2 ${location === '/struktur_organisasi' ? 'active' : ''}`} to="/struktur_organisasi">Struktur Organisasi</Link></li>
                        </ul>
                    </li>
                    {/* <li className="nav-item me-3">
                        <Link className={`nav-link ${location === '/visi_misi' ? 'active' : ''}`} to="/visi_misi">Visi & Misi</Link>
                    </li>
                    <li className="nav-item me-3">
                        <Link className={`nav-link ${location === '/visi_misi' ? 'active' : ''}`} to="/visi_misi">Struktur Organisasi</Link>
                    </li> */}
                    <li className="nav-item me-3">
                        <Link className={`nav-link ${location.includes('/berita') ? 'active' : ''}`} to="/berita">Berita</Link>
                    </li>
                    <li className="nav-item me-3">
                        <Link className={`nav-link ${location === '/gallery' ? 'active' : ''}`} to="/gallery">Gallery</Link>
                    </li>
                    <li className="nav-item me-3">
                        <Link className={`nav-link ${location === '/contact' ? 'active' : ''}`} to="/contact">Contact</Link>
                    </li>
                    {auth?.email && auth?.access_token ? (
                        <li className="nav-item me-3">
                            <a className="nav-link" href="/admin" target="_blank">Admin</a>
                        </li>
                    ) : ('')}
                    <li className="nav-item">
                        <Link className={`nav-link ${location === '/login' || location === '/forgot_password' || location.includes('/reset_password') ? 'active' : ''}`} to={auth?.email && auth?.access_token ? '/logout' : '/login'}>{auth?.email && auth?.access_token ? 'Logout' : 'Login'}</Link>
                    </li>
                </ul>
            </div>
        </nav>
    )
}

export default Navbar