import React, { useEffect, useState } from 'react'
import Navbar from '../../components/navbar/Navbar'
import ImageHeader from '../../components/image_header/ImageHeader'
import SEO from '../../components/SEO'
import Chat from '../../components/chat/Chat'
import ScrollTop from '../../components/scroll_top/ScrollTop'
import Footer from '../../components/footer/Footer'
import './terms_conditions.scss'
import axios, { BASE_URL } from '../../api/axios'

const TermsConditions = () => {
    const [termsConditions, setTermsConditions] = useState('')
    const [header, setHeader] = useState('')
    const getTermsConditions = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=terms_conditions').then((response) => {
            setTermsConditions(response.data.nilai)
        })
    }
    const getHeader = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=header_terms_conditions')
            .then((response) => {
                setHeader(response.data.nilai)
            })
    }

    useEffect(() => {
        getTermsConditions()
        getHeader()
    }, [])
    return (
        <div id="terms_conditions">
            <SEO title="Terms and Conditions" description="Hello World" />
            <Navbar />
            <ImageHeader image={BASE_URL + '/../img/' + header} />
            <div className="container mb-5">
                <div className="title_faq mb-4">
                    <img src={require('../../assets/icons/term & conditions.png')} alt="" />
                </div>
                <p dangerouslySetInnerHTML={{ __html: termsConditions }} />
            </div>
            <Chat />
            <ScrollTop />
            <Footer />
        </div>
    )
}

export default TermsConditions