import React, { useEffect, useState } from 'react'
import Navbar from '../../components/navbar/Navbar'
import ImageHeader from '../../components/image_header/ImageHeader'
import SEO from '../../components/SEO'
import Chat from '../../components/chat/Chat'
import ScrollTop from '../../components/scroll_top/ScrollTop'
import Footer from '../../components/footer/Footer'
import './berita.scss'
import axios, { BASE_URL } from '../../api/axios'
import BeritaList from './BeritaList'

const Berita = () => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const [listBerita, setlistBerita] = useState(null)
    const [header, setHeader] = useState('')
    const getBerita = async () => {
        const response = await axios.get('berita')
        setlistBerita(response.data)
        console.log(response.data)
    }
    const getHeader = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=header_berita')
            .then((response) => {
                setHeader(response.data.nilai)
            })
    }
    const tanggalToDateFormat = (tanggal) => {
        let currentTanggal = new Date(tanggal)
        return currentTanggal.toLocaleDateString("id-ID", options)
    }
    const tanggalToTimeFormat = (tanggal) => {
        let currentTanggal = new Date(tanggal)
        return currentTanggal.toLocaleTimeString("id-ID")
    }

    useEffect(() => {
        getBerita()
        getHeader()
    }, [])

    return (
        <div id="berita">
            <SEO title="Berita" description="Hello World" />
            <Navbar />
            <ImageHeader image={BASE_URL + '/../img/' + header} />
            <div className="container mb-5">
                <div className="title_faq mb-4">
                    <img src={require('../../assets/icons/berita.png')} alt="" />
                </div>
                {listBerita !== null && listBerita.length > 0 ? listBerita.map((val, key, arr) => (
                    <BeritaList id={val.id} thumbnail={val.file} title={val.title} tanggal={tanggalToDateFormat(val.createdAt)} jam={tanggalToTimeFormat(val.createdAt).substring(0, 5) + ' WIB'} key={val.id} />
                )) : (
                    <></>
                )}
            </div>
            <Chat />
            <ScrollTop />
            <Footer />
        </div>
    )
}

export default Berita