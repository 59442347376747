import React, { useState, useEffect } from 'react'
import { faEdit, faEllipsisV, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useNavigate } from 'react-router-dom'
import axios, { BASE_URL } from '../../../../api/axios'
import Swal from 'sweetalert2'
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'

const Gallery = () => {
    let nomor = 1
    const axiosPrivate = useAxiosPrivate()
    const nagivate = useNavigate()
    const [listGallery, setListGallery] = useState(null)
    const [header, setHeader] = useState('')
    const [fileHeader, setFileHeader] = useState(null)
    const [fileHeaderError, setFileHeaderError] = useState('')

    const getGallery = async () => {
        const response = await axios.get('gallery')

        if (response?.data && response?.data?.length > 0) {
            setListGallery(response.data)
        }
    }

    const handleFileHeader = (e) => {
        setFileHeader(e.target.files[0])
        setFileHeaderError('')
    }

    const handleHeaderSubmit = (e) => {
        e.preventDefault()
        const form = new FormData()
        form.append('file', fileHeader)
        form.append('nama_konfigurasi', 'header_gallery')
        axiosPrivate.post('konfigurasi/image_header', form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).catch((err) => {
            if (err.response.data?.errors) {
                if (Array.isArray(err.response.data.errors) === true) {
                    err.response.data.errors.map((val) => {
                        if (val.path === 'file') {
                            setFileHeaderError(val.message)
                        }
                    })
                } else {
                    if (err.response.data.errors.field === 'file') {
                        setFileHeaderError(err.response.data.errors.code)
                    }
                }
            }
        }).then((response) => {
            if (response.status === 200 && response.data?.message === 'Berhasil diubah') {
                Swal.fire({
                    title: 'Berhasil!',
                    text: 'Header gallery sudah berhasil diubah',
                    icon: 'success',
                }).then(() => {
                    getHeader()
                })
            }
        })
    }

    const hapus = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const response = await axiosPrivate.delete(`gallery/delete/${id}`)
                if (response.status === 200 && response.data?.message == 'Berhasil dihapus') {
                    Swal.fire(
                        'Berhasil!',
                        'Data Gallery sudah berhasil dihapuskan.',
                        'success'
                    )
                    getGallery()
                }
            }
        })
    }

    useEffect(() => {
        getGallery()
        getHeader()
    }, [])

    const getHeader = async () => {
        await axios.get('konfigurasi/?nama_konfigurasi=header_gallery', {
            withCredentials: true
        }).then((response) => {
            if (response?.data?.nilai) {
                setHeader(response.data.nilai)
            }
        })
    }

    return (
        <>
            <div className="page-header">
                <h1 className="page-title">Daftar Gallery</h1>
                <div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="https://iuranmasjid.skripsita.online/admin">Admin</a>
                        </li>
                        <li className="breadcrumb-item">
                            <a href={undefined}>Data Master</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Gallery
                        </li>
                    </ol>
                </div>
            </div>
            <div className="text-wrap mb-4">
                <div className="">
                    <div className="alert alert-info">
                        <div className="d-flex align-items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40" viewBox="0 0 24 24">
                                <path fill="#70a9ee" d="M20.05713,22H3.94287A3.02288,3.02288,0,0,1,1.3252,17.46631L9.38232,3.51123a3.02272,3.02272,0,0,1,5.23536,0L22.6748,17.46631A3.02288,3.02288,0,0,1,20.05713,22Z"></path>
                                <circle cx="12" cy="17" r="1" fill="#1170e4"></circle>
                                <path fill="#1170e4" d="M12,14a1,1,0,0,1-1-1V9a1,1,0,0,1,2,0v4A1,1,0,0,1,12,14Z"></path>
                            </svg>
                            <strong className="ms-2 fw-bolder">Informasi</strong>
                        </div>
                        <hr className="message-inner-separator" />
                        <p> Ini adalah data master. Harap pergunakan data ini dengan baik dan jika sudah tidak diperlukan
                            diperbolehkan untuk dihapus. Namun jika masih diperlukan, maka jangan dihapus karena akan mengakibatkan
                            data lainnya ikut terhapus.</p>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h3 className="card-title">Image Header Gallery</h3>
                </div>
                <div className="card-body">
                    <div className="row align-items-center">
                        <div className="col-md-8">
                            <img src={BASE_URL + '/../img/' + header} alt="Image Header" />
                        </div>
                        <div className="col-md-4">
                            <form method="POST" encType="multipart/form-data" onSubmit={handleHeaderSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="file">File Image Header</label>
                                    <input type="file" className={`form-control ${fileHeaderError !== '' ? 'is-invalid' : ''}`} id="file" name="file" onChange={handleFileHeader} required />
                                    <div className="invalid-feedback">{fileHeaderError}</div>
                                </div>
                                <button type="submit" className="btn btn-primary w-100 rounded-pill">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h3 className="card-title">Daftar Gallery</h3>
                    <div>
                        <Link to="/admin/tambah_gallery"><FontAwesomeIcon icon={faPlus} /></Link>
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead className="text-center">
                                <tr>
                                    <th className="px-3 align-middle" style={{ 'width': '5px' }}>No</th>
                                    <th className="align-middle">File</th>
                                    <th className="align-middle">Prioritas</th>
                                    <th className="align-middle">Caption</th>
                                    <th className="align-middle">Alt</th>
                                    <th className="align-middle">Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listGallery !== null ? listGallery.map((val, key, arr) =>
                                (
                                    <tr key={val.id}>
                                        <td className="text-center">{nomor++}</td>
                                        <td><img src={BASE_URL + '/../img/' + listGallery[key].file} className="w-50" /></td>
                                        <td className="text-center">{listGallery[key].prioritas}</td>
                                        <td>{listGallery[key].caption}</td>
                                        <td>{listGallery[key].alt}</td>
                                        <td className="text-center">
                                            <a href={undefined} className="cursor-pointer p-2" id="dropdown_aksi2" data-bs-toggle="dropdown" aria-expanded="false">
                                                <FontAwesomeIcon icon={faEllipsisV} className="text-secondary" aria-hidden="true" />
                                            </a>
                                            <ul className="shadow dropdown-menu px-2 py-3 ms-sm-n4 ms-n5" aria-labelledby="dropdown_aksi2">
                                                <li>
                                                    <button type="button" className="dropdown-item border-radius-md" onClick={() => nagivate(`/admin/ubah_gallery/${val.id}`)}>
                                                        <FontAwesomeIcon icon={faEdit} className="text-success" style={{ 'width': '14px' }} />&nbsp; Ubah
                                                    </button>
                                                </li>
                                                <li>
                                                    <button type="button" className="dropdown-item border-radius-md" onClick={() => hapus(val.id)}>
                                                        <FontAwesomeIcon icon={faTrash} className="text-danger" style={{ 'width': '14px' }} />&nbsp; Hapus
                                                    </button>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td colSpan={6} className="text-center">No data available in table</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Gallery